import React from 'react';
import { Wrapper, Text, Flex } from '../Ui';
import styled, { css } from 'styled-components';
import { KeyboardArrowUp } from '@styled-icons/material';
import { respondTo } from '../../theme/mixin';
const CustomWrapper = styled(Wrapper)`
  margin-top: 49px;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.bgPrimary};
  .text_color {
    color: ${({ theme }) => theme.text_link};
    margin-bottom: 10px;
  }

  .footer-text {
    font-weight: 300;
    a {
      text-decoration: underline;
      text-transform: lowercase;
      font-weight: bold;
    }
  }
  .footer-head > div {
    ${respondTo.sm`
    width: 75%;
    text-align: center;
  `}
  }
`;

const IconUp = styled(KeyboardArrowUp)`
  cursor: pointer;
  color: white;
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 24px;
  width: 24px;
`;

const Container = styled.div`
  ${({ inHome }) => inHome && 'background-color:rgb(242 190 76)'};
  max-width: 1320px;
  margin: 0 auto;
  width: 100%;
`;

const Footer = ({ footercontent }) => {
  const footerText = footercontent?.[0]?.contents?.[0]?.i18l?.content;
  const footerTitle = footercontent?.[0]?.contents?.[1]?.i18l?.title;
  return (
    <Container>
      <CustomWrapper>
        <Flex className='footer-head' direction='row' justify='center' align='center'>
          <Text bold size={16} as='div' width='100%' style={{ paddingBottom: '10px' }}>
            {footerTitle}
          </Text>
          <Text className='footer-text' size={12} as='div' width='100%'>
            {footerText}
          </Text>
        </Flex>
        <IconUp size={30} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
      </CustomWrapper>
    </Container>
  );
};

export default Footer;
