import React from 'react';
import { Typography } from '../../Ui';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Ui';
const RegisterFormSuccess = ({ isLogged }) => {
  const [t] = useTranslation();
  if (isLogged) {
    window.location.href = '/';
  }
  return (
    <div className='max-w-570 fs-lg-22 px-15'>
      <Text as='p' bold className='fs-lg-30' upper>
        GRAZIE PER ESSERTI REGISTRATO!
      </Text>

      <Text as='p'>
        Ancora un attimo di pazienza e potrai tentare di vincere i fantastici premi in palio.
      </Text>
      <br />
      <Text as='p'>
        A breve riceverai una mail per confermare il tuo indirizzo di posta elettronica.
      </Text>
      <Text as='p'>Clicca subito sul link e gioca con Leffe!</Text>
      <br />

      <Text as='p'>
        Se non l’hai ricevuta, ricordati di controllare anche fra lo spam oppure scrivici a{' '}
        <strong> assistenza@raddoppiailgustoconleffe.it</strong>
      </Text>
    </div>
  );
};

export default RegisterFormSuccess;
