import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';
import styled from 'styled-components';
import { deltaDate } from '../../../../utils/utils';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import moment from 'moment';
import { respondTo } from '../../../../theme/mixin';
registerLocale('it', it);

const DatepickerCustom = styled(DatePicker)`
  border: 2px solid #ffff;
  width: 100%;
  padding: 0 10px;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.input.bg};
  font-family: ${props => props.theme.fontFamily}, sans-serif;
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};

  ${respondTo.sm`
    height: 50px;
    font-size: 20px;
  `}
  &::placeholder {
    color: #000000;
    font-weight: 400;
  }
`;

const Datepicker = props => {
  const {
    elementConfig: { placeholder },
    validation: { maxDate, minDate },
  } = props;

  let dateFormat = 'MM/dd/yyyy';

  if (props.lang === 'it') {
    dateFormat = 'dd/MM/yyyy';
  }

  const onkeydown = e => {
    if (e.keyCode === 8) {
      e.preventDefault();
      return false;
    }
  };

  const maxdate = props.own_login_adult
    ? deltaDate(new Date(), 0, 0, -18)
    : maxDate === 'today'
    ? new Date()
    : maxDate;

  let mindate = minDate ? minDate : null;
  if (minDate && minDate == 'today') {
    mindate = new Date();
  }
  return (
    <DatepickerCustom
      dateFormat={dateFormat}
      maxDate={maxdate}
      minDate={mindate}
      locale={props.lang}
      onKeyDown={onkeydown}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      placeholderText={placeholder}
      {...props}
    />
  );
};

export default Datepicker;
