import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../Interactions';
import PreInteraction from '../../PreInteraction';
import ResultGame from '../../Response/ResultGame';
import { useSelector } from 'react-redux';
const CardHandler = props => {
  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
  } = props;
  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };
  const [predictData, setPredictData] = useState(false);

  const wins = useSelector(state => state.user?.user?.wins);
  let listWins = [];
  if (wins) {
    listWins = wins?.filter(item => item.pending);
  }
  content = (
    <div>
      {showResult ||
      (played && props.g.won) ||
      (played && wins.length > 0 && listWins[0]?.pending && listWins[0]?.show_prize_form) ? (
        <ResultGame {...props} onSetShowResult={setShowResult} />
      ) : !showInteraction && steps.preinteraction.data === null ? (
        <PreInteraction
          preinteraction={preinteraction}
          onShowInteractionHandler={onShowInteractionHandler}
          setPredictData={setPredictData}
          {...props}
        />
      ) : (
        <Card
          typeInteraction='interaction'
          predictData={predictData}
          onShowResult={onShowResult}
          {...props}
        />
      )}
    </div>
  );

  return content;
};

CardHandler.propTypes = {
  action: PropTypes.object,
};

export default CardHandler;
