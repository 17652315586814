import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../../Ui';
import iconParticipe from '../../../../assets/images/boxWin.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getFormInfo } from '../../../../store/actions/user';
import { useHistory } from 'react-router';
import { ChevronRight } from '@styled-icons/boxicons-regular';
import { respondTo } from '../../../../theme/mixin';
import { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import customaxios from '../../../../config/axios-refresh-token';
import { checkFormPrizeAccepted } from '../../../../store/actions';
import { checkFormCompiled } from '../../../../store/actions';
import { Input } from '../../../Ui';
import parse from 'html-react-parser';
import { Modal } from '../../../Ui';
import { getUserInfo } from '../../../../store/actions';
import { getGame } from '../../../../store/actions';
const ResultDynamicWrapper = styled.div`
  position: relative;
  // text-align: ${({ click }) => (click ? 'left' : 'center')};
  //margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: ${({ click }) => (click ? 'stretch' : 'center')};
  height: 100%;
  .btnDesk,
  .btnMob {
    width: fit-content;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .btnDesk {
    display: none;
    ${respondTo.sm` 
        display: block;
    `};
  }
  .btnMob {
    display: block;
    ${respondTo.sm` 
        display: none;
    `};
  }
`;

const ResultDynamic = props => {
  const {
    dynamicData: { title = '', content },
    thePrize: { title: titleWin = '', content: contentWin, extra },
    wingame,
    click,
    playslog_id,
    id_game,
    form,
    //extra,
    formend,
    show_prize_form,
    attemptslimit,
    slug,
    lang,
    id,
  } = props;
  const userInfo = useSelector(state => state.user.user?.userinfo);
  const [loading, setLoading] = useState(false);
  const [showFormResponse, setShowFormResponse] = useState(false);
  const [info, setInfo] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const wins = useSelector(state => state.user.user?.wins);
  let listWins = [];
  if (wins) {
    listWins = wins?.filter(item => item.pending);
  }
  const clickHandler = () => {
    if (form) {
      if (formend) return;
      dispatch(getFormInfo(id_game, playslog_id)).then(() => {
        history.push('/form-win');
      });
    }
  };
  let dataForm;
  if (form?.data) {
    dataForm = JSON.parse(form?.data);
  }
  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const submitHandler = e => {
    const objData = {};
    // for (const property in formData) {
    //   objData[property] = formData[property].value;
    // }
    objData['prize'] = 0;
    const data = new FormData();

    data.append('formId', form.formId);
    data.append('data', JSON.stringify(objData));
    data.append('section', 'form');
    let winMinifrigo = listWins.filter(ele => ele.show_prize_form);
    data.append('sid', winMinifrigo[0]?.sid);
    if (form.type === 'post') {
      data.append('playslog', playslog_id);
    }
    setLoading(true);
    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          if (response.data.data.status) {
            setShowFormResponse(true);
          } else {
            setShowModal(true);
            setInfo(response.data.data.info);
          }
          if (
            response.data.data &&
            JSON.parse(response.data.data.data) &&
            JSON.parse(response.data.data.data).prize
          ) {
            checkFormPrizeAccepted(JSON.parse(response.data.data.data).prize);
          }
          checkFormCompiled(form.formId);
        }
      })
      .catch(error => {
        setLoading(false);
        setShowModal(true);
        setInfo(error.response.data.error);
      });
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };
  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }
  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    onSetFormValid,
    imgPreviewUrl,
    districts,
    cities,
    provinces,
    zipcode,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  const closeModalFormResponse = () => {
    setShowFormResponse(false);
    dispatch(getGame(id, slug));
    dispatch(getUserInfo());
    history.push('/');
  };
  let inputs = inputArr
    .filter(i => i.name === 'prize')
    .map(inp => {
      return (
        <Input
          error={inp.errorMessage}
          key={inp.name}
          value={inp.value}
          elementType={inp.elementType}
          elementConfig={inp.elementConfig}
          changed={inputChangedHandler}
          label={inp.label}
          isvalid={inp.valid}
          readonly={inp.readonly}
          istouched={inp.validation.touched}
          showError={showError}
          firstSubmit={firstSubmit}
          startdate={inp.value}
          changedDatePicker={date => datePickerHandler(date, inp.name)}
          fileUploadHandler={fileUploadHandler}
          deleteFileHandler={deleteFileHandler}
          imagePreviewUrl={imgPreviewUrl}
          lang={lang}
          inModal={true}
          radioInputs={inp.inputs}
          setImage={setImage}
          setFormValid={onSetFormValid}
          formSetSelectedFiles={formSetSelectedFiles}
          resetFormHandler={resetFormHandler}
          districts={districts}
          cities={cities}
          zipcode={zipcode}
          provinces={provinces}
          {...inp}
        />
      );
    });
  useEffect(() => {
    if (!show_prize_form) {
      const objData = {};
      objData['prize'] = 1;
      const data = new FormData();
      data.append('data', JSON.stringify(objData));
      data.append('formId', form.formId);
      data.append('section', 'form');
      if (form.type === 'post') {
        data.append('playslog', playslog_id);
      }
      let winGlass = listWins.filter(ele => !ele.show_prize_form);
      data.append('sid', winGlass[0]?.sid);
      customaxios
        .post(`game/${slug}/${id}/data`, data)
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(error => {});
    }
  }, [show_prize_form, wins]);
  return (
    <ResultDynamicWrapper click={click}>
      <div className='container-result-img'>
        <Text size={25} bold upper className='text-result'>
          Hai vinto!
        </Text>
        <img className='w-100' src={click ? wingame : iconParticipe} alt='' />
      </div>

      <div className='content-prize'>
        {!show_prize_form ? (
          <Text size={16}>
            Complimenti {userInfo.firstname} {userInfo.lastname}!
          </Text>
        ) : (
          ''
        )}{' '}
        <Text primary size={16} bold>
          {show_prize_form ? 'Richiedi ' : 'Ritira '} subito il tuo{' '}
          {show_prize_form ? '' : 'iconico'} {titleWin}.
        </Text>
        {!show_prize_form ? (
          <Text size={16} light>
            A breve riceverai una mail che conferma la tua vincita, mostrala allo staff per ottenere
            il tuo premio.
          </Text>
        ) : (
          ''
        )}
        {!formend && show_prize_form ? (
          <div>
            <Text size={16} light>
              Accetta il premio, fai tap sul pulsante conferma e completa il form con i dati
              necessari.
            </Text>
            <div className='mt-20 inputs-refuse-accept'>{inputs}</div>

            {inputs.find(item => item.key === 'prize')?.props.value === '1' ? (
              <Button active bold upper fullwidth hover onClick={clickHandler}>
                Conferma
              </Button>
            ) : (
              <Button
                loading={loading}
                upper
                active
                center
                bold
                fullwidth
                hover
                white
                style={{ marginTop: '15px', display: 'block', textAlign: 'center' }}
                onClick={formSubmitHandler}
                disabled={loading}
              >
                Conferma
              </Button>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal show={showModal} close={closeModal}>
        <p>{info && parse(info)}</p>
      </Modal>
      <Modal show={showFormResponse} close={closeModalFormResponse}>
        <Text size={18} bold>
          GRAZIE PER AVER GIOCATO!
        </Text>
        <p>Ci spiace che tu abbia scelto di non accettare il premio.</p>
      </Modal>
    </ResultDynamicWrapper>
  );
};

export default ResultDynamic;
