import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, Logo, Text, Button, Loader } from '../Ui';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import { respondTo } from '../../theme/mixin';
import { Close } from '@styled-icons/material';
import { ReactComponent as Menu } from '../../assets/images/iconMenu.svg';
import LogoHeader from '../../assets/images/iconApp.svg';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { numberWithDots } from '../../utils/utils';
import LinkDownload from '../Navigation/NavigationItems/NavigationItem/LinkDownload/LinkDownload';
import glassimg from '../../../src/assets/images/glassimg.svg';
import Logout from '../Logout/Logout';
import { useTranslation } from 'react-i18next';
const NavContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.default_colortext};
  box-shadow: ${({ theme }) => theme.shadow};

  ${respondTo.md`
    width: auto;
		display: flex;
    margin-right: auto;
    top: 0;
    position: static;
    border-top: none;
    box-shadow: none;
    background-color:transparent;
	`};
`;

const HeaderSection = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  .header-effect {
    background-color: #a6e2ff4f;
    backdrop-filter: blur(9px);
  }
  ${respondTo.sm`
    
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.navBorder};
  background-color: #f9be00;
  backdrop-filter: blur(45px);
  box-shadow: 0px 5px 15px #0000001a;
  padding: 10px 15px;
  a {
    font-size: 14px;
    font-weight: bold;
  }
  ${respondTo.sm`
    margin:0;
    padding: 15px;
	`};

  .logo {
    ${respondTo.sm` 
      margin-right:125px
    `}
  }

  .rightWrapper {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 10px;

      ${respondTo.sm`
        margin-right: 20px;
      `}
    }
  }
`;

const Header = props => {
  const { logo, logout, isLogged, multipromo, i18l, isAdult, showModalLogout, setShowModalLogout } =
    props;
  const [t] = useTranslation();
  const loadingCognito = useSelector(state => state.auth.loadingCognito);

  const userInfo = useSelector(state => state.user.user.userinfo);
  const points = useSelector(state => state.user.user.points);
  const { refreshToken } = useSelector(state => state.auth || {});
  const refresh_token = refreshToken || localStorage.getItem('refresh-token');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const webview = localStorage.getItem('webview');
  const scrollHandler = () => {
    if (window.scrollY > 100) {
      document.querySelector('.header-container').classList.add('header-effect');
      location.pathname === '/' && document.querySelector('.logo').classList.remove('logo-hide');
    } else {
      document.querySelector('.header-container').classList.remove('header-effect');
      location.pathname === '/' && document.querySelector('.logo').classList.add('logo-hide');
    }
  };

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer className='header-container' path={location.pathname}>
          <Logo logo={LogoHeader} className='logo ' />

          {loadingCognito ? (
            <Loader show={loadingCognito} />
          ) : (
            <>
              {isLogged ? (
                <>
                  <NavContainer isOpen={isMenuOpen}>
                    <NavigationItems
                      isLogged={isLogged}
                      multipromo={multipromo}
                      i18l={i18l}
                      logout={logout}
                      setIsMenuOpen={setIsMenuOpen}
                      clicked={() => setIsMenuOpen(false)}
                      webview={webview}
                      refresh_token={refresh_token}
                      setShowModalLogout={setShowModalLogout}
                    />
                  </NavContainer>
                  <div className='no-mobile'>
                    <div className='logout' onClick={() => setShowModalLogout(true)}>
                      <Logout label={t('auth.logoutlabel')} />
                    </div>
                  </div>
                  <MenuContainerMobile onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen ? (
                      <Close size={34} style={{ cursor: 'pointer' }} />
                    ) : (
                      <Menu className='menu' />
                    )}
                  </MenuContainerMobile>
                </>
              ) : (
                <div className='rightWrapper'>
                  <a
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                    href='/auth/login'
                  >
                    Accedi
                    <img src={glassimg} width='12' height='19' alt='' />
                  </a>
                </div>
              )}
            </>
          )}
        </HeaderContainer>
      </Wrapper>
    </HeaderSection>
  );
};

const MenuContainerMobile = styled.div`
  display: flex;

  .menu {
    width: 34px;
    cursor: pointer;
  }
  ${respondTo.md`
		display: none;
	`}
`;

const MenuContainerDesk = styled.div`
  display: none;

  ${respondTo.md`
		display: block;
  `}
`;

Header.propTypes = {
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
