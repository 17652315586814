import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import checkicon from '../../../../assets/images/checkicon.svg';
import { respondTo } from '../../../../theme/mixin';
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  margin: -1px;
  overflow: hidden;
  visibility: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
  opacity: 0;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  .switch {
    display: inline-block;
    height: 30px;
    position: relative;
    width: 56px;
  }
  label {
    display: inline-block;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    justify-content: space-between;
    .slider {
      background-color: #0000;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.4s;
      &:before {
        background-color: #fff;
        bottom: 3px;
        content: url(${props => (props.value === 0 ? '' : checkicon)});
        height: 24px;
        left: 3px;
        position: absolute;
        transition: 0.4s;
        width: 24px;
      }

      background-color: ${props =>
        props.value === 0 ? props.theme.primary : props.theme.input.checkbox};
      transition: all 150ms;

      &.round:before {
        ${props =>
          props.value === 1
            ? 'transition: all 150ms;transform: translateX(25px);'
            : 'transition: all 150ms;transform: translateX(0);'}
      }
    }
    .slider.round {
      border: ${props => (props.value === 0 ? props.theme.primary : props.theme.input.checkbox)};
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
    a {
      display: inline;
      color: ${props => props.theme.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }
  label span,
  span,
  label span a {
    max-width: calc(100% - 65px);
    font-size: 14px;
    ${respondTo.sm`
      font-size: 22px;
    `}
    color: ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? '#921a28'
        : props.theme.text_primary};
  }
`;

const CheckboxSlide = props => {
  return (
    <CheckboxContainer
      isvalid={props.isvalid}
      istouched={props.istouched}
      firstSubmit={props.firstSubmit}
      value={props.value ? parseInt(props.value) : 0}
      className='check_contact_slide check_share'
    >
      <label htmlFor={props.name}>
        <span>{parse(props.label)}</span>
        <div className='switch'>
          <div className='slider round'></div>
          <HiddenCheckbox {...props} />
        </div>
      </label>
    </CheckboxContainer>
  );
};

export default CheckboxSlide;
