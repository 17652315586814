import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Box = props => {
  return <BoxWrap {...props}>{props.children}</BoxWrap>;
};

const BoxWrap = styled.div`
  overflow: hidden;
  background-color: ${props => (props.primary ? props.theme.primary : '#fff')};
  box-shadow: 0px 5px 15px #00000026;

  padding: ${({ padding }) => (padding ? padding : '25px 15px')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${props => (props.fullheight ? '100%' : 'auto')};
`;

Box.propsTypes = {
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  fullheight: PropTypes.bool,
  primary: PropTypes.bool,
};

export default Box;
