import React from 'react';
import PropTypes from 'prop-types';
import {
  ScratchAndWinHandler,
  MemoryHandler,
  SurveyQuizHandler,
  CardHandler,
  UploadReceiptHandler,
  ClickHandler,
} from '../../Handlers';
import RatephotoHandler from '../../Handlers/RatephotoHandler';

const MechanicGame = props => {
  const {
    g: { interaction },
  } = props;
  let mechanicgame = null;
  switch (interaction.type) {
    case 'survey-quiz':
      mechanicgame = <SurveyQuizHandler {...props} />;
      break;
    case 'memory':
      mechanicgame = <MemoryHandler {...props} />;
      break;
    case 'scratch-and-win':
      mechanicgame = <ScratchAndWinHandler {...props} />;
      break;
    case 'card':
      mechanicgame = <CardHandler {...props} />;
      break;
    case 'upload-receipt':
      mechanicgame = <UploadReceiptHandler {...props} />;
      break;
    case 'ratephoto':
      mechanicgame = <RatephotoHandler {...props} />;
      break;
    case 'click':
      mechanicgame = <ClickHandler {...props} />;
      break;

    default:
      mechanicgame = <CardHandler {...props} />;
  }

  return <React.Fragment>{mechanicgame}</React.Fragment>;
};

MechanicGame.propTypes = {
  interaction: PropTypes.object,
};

export default MechanicGame;
