import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FacebookCircle, InstagramAlt } from 'styled-icons/boxicons-logos';
import { respondTo } from '../../../../theme/mixin';

const LinkContent = styled.li`
  display: ${props => (props.isMobileVertical ? 'block' : 'inline-block')};
  margin: 6px 4px;
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};
  ${respondTo.sm`
    display: ${props => (props.isMobileVertical ? 'inline-block' : 'inline-block')};
  `}
`;

const LinkA = styled.a`
  margin: 0 4px;
  font-size: 12px;
  padding: 0px;
  color: ${props => props.theme.text.tertiary};
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const Link = props => {
  const {
    stile,
    type,
    upper,
    i18l: { title, content },
  } = props;

  let st = {};
  let link = null;

  if (stile) {
    st = JSON.parse(stile);
  }

  switch (type) {
    case 'social':
      if (title === 'facebook') {
        link = (
          <LinkA href={content} target='_blank' rel='noopener' style={st}>
            <FacebookCircle size={24} />
          </LinkA>
        );
      }
      if (title === 'instagram') {
        link = (
          <LinkA href={content} target='_blank' rel='noopener' style={st}>
            <InstagramAlt size={24} />
          </LinkA>
        );
      }
      break;
    case 'link':
      link = (
        <LinkA href={content} target='_blank' rel='noopener' style={st}>
          {title}
        </LinkA>
      );
      break;
    default:
      link = null;
      break;
  }

  return <LinkContent {...props}>{link}</LinkContent>;
};

Link.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  stile: PropTypes.string,
};

export default Link;
