import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
const InputField = styled.input`
  border: 2px solid #ffff;
  color: ${props => props.theme.primary};
  padding: 0 10px;
  background-color: ${props => props.theme.input.bg};
  font-family: ${props => props.theme.fontFamily}, sans-serif;
  width: ${props => (props.type === 'checkbox' ? '20px' : '100%')};
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};

  ${respondTo.sm`
    height: 50px;
    font-size: 20px;
  `}
  &::placeholder {
    color: #000000;
    font-weight: 400;
  }
`;

export default InputField;
