import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';

const ScratchOffContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ScratchOffParent = styled.div`
  position: relative;
  width: 160px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  opacity: ${props => (props.islocked ? 0.6 : 1)};
  border: 4px solid #fff;
  ${respondTo.sm`
    	width: 239px;
	  	height: 119px;
  `};
`;

const Canvas = styled.canvas`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

const HiddenPart = styled.div`
  width: 100%;
  height: 100%;
  display: ${props => (props.isHidden ? 'none' : 'block')};
  & img {
    margin: auto;
    display: block;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: ${props => (props.isHidden ? 'none' : 'block')};
  }
`;

const ScratchOff = props => {
  const {
    islocked,
    hasdynamic,
    unlock,
    onSetAction,
    iswinning,
    hiddenpart,
    brand,
    onShowResult,
    onSetAttempts,
  } = props;

  const [isHidden, setIsHidden] = useState(true);

  const canvasRef = useRef(null);
  const parentRef = useRef(null);

  let canvas = null;
  let parent = null;
  let ctx = null;
  let isDrawing = false;
  let lastPoint = null;
  let brush = null;
  let cover = null;

  var a = 10;

  useEffect(() => {
    canvas = canvasRef.current;
    parent = parentRef.current;
    ctx = canvas.getContext('2d');
    canvas.width = parentRef.current.offsetWidth;
    canvas.height = parentRef.current.offsetHeight;

    brush = new Image();
    brush.crossOrigin = 'Anonymous';
    brush.src = '';

    cover = new Image();
    cover.crossOrigin = 'Anonymous';
    cover.src = brand;
    canvas.setAttribute('crossOrigin', 'Anonymous');

    cover.onload = () => {
      ctx.drawImage(cover, 0, 0, canvas.width, canvas.height);
    };

    canvas.addEventListener('mousedown', touchStart);
    canvas.addEventListener('touchstart', touchStart);
    canvas.addEventListener('mousemove', touchMove);
    canvas.addEventListener('touchmove', touchMove);
    canvas.addEventListener('mouseup', touchEnd);
    canvas.addEventListener('touchend', touchEnd);
    canvas.addEventListener('handlePercentage', handlePercentage);
    canvas.addEventListener('getFilledInPixels', getFilledInPixels);

    setTimeout(() => {
      setIsHidden(false);
    }, 100);

    return () => {
      canvas.removeEventListener('mousedown', touchStart);
      canvas.removeEventListener('touchstart', touchStart);
      canvas.removeEventListener('mousemove', touchMove);
      canvas.removeEventListener('touchmove', touchMove);
      canvas.removeEventListener('mouseup', touchEnd);
      canvas.removeEventListener('touchend', touchEnd);
      canvas.removeEventListener('handlePercentage', handlePercentage);
      canvas.removeEventListener('getFilledInPixels', getFilledInPixels);
    };
  }, [islocked]);

  const touchStart = event => {
    if (islocked) {
      return;
    } else {
      isDrawing = true;
      lastPoint = getPosition(event);
      ctx.globalCompositeOperation = 'destination-out';
    }
  };

  const touchMove = event => {
    if (!isDrawing) return;
    event.preventDefault();

    const a = lastPoint;
    const b = getPosition(event);
    const dist = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
    const angle = Math.atan2(b.x - a.x, b.y - a.y);
    const offsetX = brush.width / 2;
    const offsetY = brush.height / 2;

    for (let x, y, i = 0; i < dist; i++) {
      x = a.x + Math.sin(angle) * i - offsetX;
      y = a.y + Math.cos(angle) * i - offsetY;
      ctx.drawImage(brush, x, y);
    }

    lastPoint = b;
    handlePercentage(getFilledInPixels(32));
  };

  const touchEnd = event => {
    isDrawing = false;
  };

  const getPosition = event => {
    let target = canvas;
    let offsetX = 0;
    let offsetY = 0;

    if (target.offsetParent !== undefined) {
      while ((target = target.offsetParent)) {
        offsetX += target.offsetLeft;
        offsetY += target.offsetTop;
      }
    }

    const x = (event.pageX || event.touches[0].clientX) - offsetX;
    const y = (event.pageY || event.touches[0].clientY) - offsetY;
    return { x, y };
  };

  const handlePercentage = filledInPixels => {
    filledInPixels = filledInPixels || 0;
    if (filledInPixels > 20) {
      isDrawing = false;
      if (canvas) {
        onSetAttempts();
        if (!hasdynamic) {
          if (iswinning) {
            setTimeout(() => {
              onShowResult();
            }, 1000);
          }
        } else {
          unlock();
          onSetAction();
        }
        parent.removeChild(canvas);
      }
    }
  };

  const getFilledInPixels = stride => {
    let canvasWidth = canvas.width;
    let canvasHeight = canvas.height;

    if (!stride || stride < 1) {
      stride = 1;
    }

    let pixels = ctx.getImageData(0, 0, canvasWidth, canvasHeight),
      pdata = pixels.data,
      l = pdata.length,
      total = l / stride,
      count = 0;

    for (let i = 0; i < l; i += stride) {
      if (parseInt(pdata[i], 10) === 0) {
        count++;
      }
    }

    return Math.round((count / total) * 100);
  };

  return (
    <ScratchOffContainer>
      <ScratchOffParent ref={parentRef} islocked={islocked}>
        <Canvas ref={canvasRef} />
        <HiddenPart isHidden={isHidden}>{isHidden ? null : <img src={hiddenpart} />}</HiddenPart>
      </ScratchOffParent>
    </ScratchOffContainer>
  );
};

export default ScratchOff;
