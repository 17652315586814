import React, { useState } from 'react';
import UploadReceipt from '../../Interactions/UploadReceipt';

const UploadReceiptHandler = props => {
  const [showResult, setShowResult] = useState(false);

  const onShowResult = () => {
    setShowResult(true);
  };

  return (
    <React.Fragment>
      {showResult ? <div>result</div> : <UploadReceipt onShowResult={onShowResult} {...props} />}
    </React.Fragment>
  );
};

export default UploadReceiptHandler;
