import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Loader, Text } from '../../components/Ui';
import ContentSlide from '../../components/ContentSlide';
import { respondTo } from '../../theme/mixin';
import Banner from '../../components/banner';
import CustomSlider from '../../components/customSlider';
import Select from '../../components/Ui/Input/Select/Select';
import moment from 'moment';
import { Modal } from '../../components/Ui';
import { useHistory } from 'react-router';
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;
const ContainerTextYellow = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 15px;
  .bgYellow {
    background-color: #f9be00;
  }
`;
const ContainerFormAdult = styled.div`
  max-width: 570px;
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
  > div {
    display: flex;
    gap: 30px;
  }
  .button-form-adult {
    margin-top: 30px;
  }
`;

const FormIsAdult = props => {
  let gg = [];
  let mm = [];
  let y = [];
  let currentYear = moment().year();
  let [selectedYear, setSelectedYear] = useState('');
  let [selectedDay, setSelectedDay] = useState('');
  let [selectedMonth, setSelectedMonth] = useState('');
  let [modalShow, setModalShow] = useState(false);
  let [contentModal, setContentModal] = useState('');
  for (let i = 1; i <= 31; i++) {
    if (i < 10) {
      i = '0' + i;
    }
    gg.push(i);
  }
  for (let i = 1; i <= 12; i++) {
    if (i < 10) {
      i = '0' + i;
    }
    mm.push(i);
  }
  for (let i = currentYear; i >= 1923; i--) {
    y.push(i);
  }

  const verifyIfAdult = function () {
    if (selectedDay && selectedMonth && selectedYear) {
      const birthDate = new Date(selectedYear, +selectedMonth - 1, selectedDay);
      const dateNow = new Date();
      const years = dateNow.getFullYear() - birthDate.getFullYear();
      const adult =
        years > 18 ||
        (years === 18 &&
          (dateNow.getMonth() > birthDate.getMonth() ||
            (dateNow.getMonth() === birthDate.getMonth() &&
              dateNow.getDate() >= birthDate.getDate())));

      if (!adult) {
        setModalShow(true);
        setContentModal('Devi essere maggiorenne per poter proseguire');
      } else {
        props.onSetIsAdult(true);
        localStorage.setItem('isAdult', true);
      }
    } else {
      setContentModal('Devi selezionare tutti i campi');
      setModalShow(true);
    }
  };
  const closeModal = () => {
    setModalShow(false);
  };
  return (
    <>
      <Modal show={modalShow} close={() => closeModal()}>
        <Text upper bold className='fs-lg-30'>
          {contentModal}
        </Text>
      </Modal>
      <Wrapper isSelect={false}>
        <ContainerTextYellow>
          <Text bold upper className='bgYellow fs-lg-49'>
            LEFFE È NATA
          </Text>
          <Text bold upper className='bgYellow fs-lg-49'>
            NEL 1240.
          </Text>
          <Text bold upper className='bgYellow fs-lg-49'>
            E TU?
          </Text>
          <Text light className='mt-20 fs-lg-30'>
            Ti diamo il benvenuto nel mondo di Leffe. <br /> Inserisci la tua data di nascita per
            accedere.
          </Text>
          <ContainerFormAdult>
            <div>
              <Select
                required={true}
                onChange={event => setSelectedDay(event.target.value)}
                className='select-contact'
              >
                <option value=''>GG</option>
                {gg.map(ele => (
                  <option key={ele} value={ele}>
                    {ele}
                  </option>
                ))}
              </Select>
              <Select
                required={true}
                className='select-contact'
                onChange={event => setSelectedMonth(event.target.value)}
              >
                <option value=''>MM</option>
                {mm.map(ele => (
                  <option key={ele} value={ele}>
                    {ele}
                  </option>
                ))}
              </Select>
              <Select
                required={true}
                onChange={event => setSelectedYear(event.target.value)}
                className='select-contact'
              >
                <option value=''>AAAA</option>
                {y.map(ele => (
                  <option key={ele} value={ele}>
                    {ele}
                  </option>
                ))}
              </Select>
            </div>
            <Button
              onClick={() => verifyIfAdult()}
              noIcon
              hover
              upper
              bold
              fullwidth
              className='button-form-adult'
              active
            >
              Prosegui
            </Button>
          </ContainerFormAdult>
        </ContainerTextYellow>
      </Wrapper>
    </>
  );
};

export default FormIsAdult;
