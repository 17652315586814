import React, { Suspense, useState, useEffect } from 'react';
import { Button, Loader, Modal, Text } from '../../components/Ui';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SessionExpired from '../../components/SessionExpired';
import { connect, useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Link, useLocation } from 'react-router-dom';
import { getFormInfo } from '../../store/actions/user';

const MainContent = styled.div`
  flex: 1 0 auto;
  width: 100%;
  a {
    text-transform: uppercase;
  }

  // ${respondTo.sm`
	// 	padding-top: 0;
	// `}
`;
const ContainerGlobal = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff4e0;
`;

const BaseLayout = props => {
  const dispatch = useDispatch();
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    appConfig: { i18l },
    firstAccess,
    footer_brand_bar,
  } = props;
  const { refreshToken } = useSelector(state => state.auth || {});
  const refresh_token = refreshToken || localStorage.getItem('refresh-token');

  const [accessErrorModal, setAccessErrorModal] = useState(false);
  const [inIframe, setInIframe] = useState(false);
  const wins = useSelector(state => state.user.user?.wins);
  const [isModal, setIsModal] = useState(false);

  const [winState, setWinState] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (winState) {
      if (wins?.length > 0) {
        if (location.pathname !== '/form-win') {
          if (!/game\/\d+$/.test(location.pathname)) {
            const listWins = wins?.filter(item => item.pending);
            setIsModal(listWins[0]?.pending && listWins[0]?.show_prize_form);
            setWinState(false);
          }
        }
      }
    }
  }, [wins]);

  useEffect(() => {
    if (window.self !== window.top) {
      setInIframe(true);
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.expire_date_limit) {
      const today = new Date();

      if (Date.parse(today) > Date.parse(userInfo.expire_date_limit.replaceAll('-', '/'))) {
        props.history.push('/account-deleted');
      }
    }
  }, [props.history, userInfo]);

  let lazy = null;
  const clickHandler = () => {
    if (wins?.length > 0 && location.pathname !== '/form-win') {
      const listWins = wins.filter(item => item.pending && item.show_prize_form);
      listWins.length > 0 && dispatch(getFormInfo(listWins[0]?.game_id, listWins[0]?.id));
    }
    setIsModal(false);
  };
  const [showModalLogout, setShowModalLogout] = useState(false);
  const closeModalLogout = () => {
    setShowModalLogout(false);
  };
  const Content = (
    <ContainerGlobal>
      <MainContent>
        <Header
          isAdult={true}
          isLogged={isLogged}
          logo={logo}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          logout={logout}
          bootApp={bootApp}
          langs={langs}
          oauths={oauths}
          ownLogin={ownLogin}
          multipromo={multipromo}
          oauthInfo={oauthInfo}
          userInfo={userInfo}
          oauth_profile_url={oauth_profile_url}
          i18l={i18l}
          showModalLogout={showModalLogout}
          setShowModalLogout={setShowModalLogout}
        />

        <main>
          <C isLogged={isLogged} />
        </main>
      </MainContent>
      {!inIframe ? (
        <Footer footercontent={footercontent} inHome={location.pathname === '/'} />
      ) : null}
      <Modal
        show={showModalLogout}
        close={closeModalLogout}
        title='SEI SICURO DI VOLERTI DISCONNETTERE?'
      >
        <Button
          active
          upper
          bold
          type='button'
          fullwidth
          className='mb-lg-20'
          onClick={closeModalLogout}
        >
          Annulla
        </Button>

        <Button
          theme
          primary
          bold
          upper
          fullwidth
          className=' btn btn-cancel'
          onClick={() => logout(refresh_token)}
        >
          Conferma
        </Button>
      </Modal>
      <Modal
        show={accessErrorModal}
        close={() => {
          setAccessErrorModal(false);
        }}
      >
        <Text upper bold className='fs-lg-30'>
          {accessErrorModal}
        </Text>
      </Modal>
      <Modal show={isModal} close={() => setIsModal(false)}>
        <Text>Complimenti, Hai un premio che ti aspetta!</Text>
        <Link to='/'>
          <Button active hover onClick={clickHandler} className='mt-20' bold>
            Clicca qui
          </Button>
        </Link>
      </Modal>
    </ContainerGlobal>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired isExpired={isExpired} />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: 'base',
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    logoinverted: state.app.config.logoinverted,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    firstAccess: state.user.user.first_access_done,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: refreshToken => dispatch(actionCreators.logout(refreshToken)),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
