import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
const CustomWrapper = styled.div`
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 20px;
  padding: 0 15px;

  ${respondTo.sm`
  margin-top: 30px;
  padding: 0;
  `}
  ${({ inHome }) => inHome && 'border-top: 1px solid white'};
`;

export default CustomWrapper;
