import React, { useState, useEffect } from 'react';
import customAxios from '../../config/axios-refresh-token';
import styled from 'styled-components';
import errormsg1 from '../../assets/images/error_msg2.png';
import { respondTo } from '../../theme/mixin';
import { Loader } from '../Ui';
import { Text } from '../Ui';
const ContainerGeolocation = styled.div`
  font-weight: bold;
  font-size: 16px;
  .show-error {
    color: #921a28;
  }
  img {
    margin-top: -5px;
    max-width: 120px;
    ${respondTo.sm`
      max-width: 200px;
    `}
  }
`;
const GeoLocationChecker = ({ setLocals, showError }) => {
  const [isGeoLocationEnabled, setIsGeoLocationEnabled] = useState(null);

  useEffect(() => {
    checkGeoLocation();
    navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
      permissionStatus.onchange = () => {
        checkGeoLocation();
        getGeoLocation();
      };
    });
  }, []);

  const checkGeoLocation = () => {
    navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
      if (permissionStatus.state === 'granted') {
        setIsGeoLocationEnabled(true);
        getGeoLocation();
      } else {
        setIsGeoLocationEnabled(false);
      }
    });
  };

  let distance = localStorage.getItem('distance');
  const successGeolocation = position => {
    setIsGeoLocationEnabled(true);
    getLocals(position.coords.latitude, position.coords.longitude, distance);
    localStorage.setItem('lat', position.coords.latitude);
    localStorage.setItem('lng', position.coords.longitude);
  };

  const errorGeolocation = error => {
    alert(error.message);
    getLocals(null, null, distance);
    setIsGeoLocationEnabled(false);
  };
  const getLocals = (lat, lng, distance) => {
    return new Promise((resolve, reject) => {
      customAxios
        .get(process.env.REACT_APP_API_URL + 'app/getenabledpoc', {
          params: {
            lat: lat,
            lng: lng,
            distance: distance,
          },
        })
        .then(response => {
          if (response.status === 200) {
            setLocals(response.data);
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    });
  };
  const getGeoLocation = () => {
    if ('geolocation' in navigator && navigator.geolocation !== undefined) {
      navigator.geolocation.getCurrentPosition(successGeolocation, () =>
        setIsGeoLocationEnabled(false),
      );
    } else {
      setIsGeoLocationEnabled(false);
    }
  };

  const handleEnableGeoLocation = () => {
    if ('geolocation' in navigator && navigator.geolocation !== undefined) {
      navigator.geolocation.getCurrentPosition(successGeolocation, errorGeolocation);
    }
  };

  return (
    <ContainerGeolocation>
      {isGeoLocationEnabled === null && <Loader show={true} />}

      {isGeoLocationEnabled === true && (
        <p className='mb-20 text-underlined'>ATTIVA LA GEOLOCALIZZAZIONE*</p>
      )}
      {showError && !isGeoLocationEnabled && <img src={errormsg1} alt='' width={200} />}
      {isGeoLocationEnabled === false && (
        <div
          className={`${showError ? 'show-error text-underlined' : 'text-underlined'}`}
          onClick={handleEnableGeoLocation}
        >
          ATTIVA LA GEOLOCALIZZAZIONE*
        </div>
      )}
      {isGeoLocationEnabled === false && (
        <Text color='#ff0000' className='mb-20' size='12'>
          *Devi prima dare i permessi per la geolocalizzazione dal tuo dispositivo
        </Text>
      )}
    </ContainerGeolocation>
  );
};

export default GeoLocationChecker;
