import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
const LogoContainer = styled.div`
  width: 40px;
  ${respondTo.sm`
  width: ${props => (props.theme.header_logo_size ? props.theme.header_logo_size + 'px' : 'auto')};
  `}
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Logo = props => {
  const { nolink, logo } = props;

  let logoContent = null;

  if (nolink) {
    logoContent = <img src={logo} alt='' />;
  } else {
    logoContent = (
      <Link to='/'>
        <img src={logo} alt='' />
      </Link>
    );
  }

  return <LogoContainer {...props}>{logoContent}</LogoContainer>;
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
