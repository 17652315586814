import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import logoSlider from '../../assets/images/iconApp.svg';
import { respondTo } from '../../theme/mixin';
import { Button, Logo, Text } from '../Ui';
import { ReactComponent as BannerIcon } from '../../assets/images/icon_file.svg';

const Container = styled.div`
  position: relative;
  padding: 0;
  overflow: hidden;
  height: ${({ inFlex }) => (inFlex ? 'auto' : '100%')};
  z-index: 1;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  & > img,
  picture > * {
    object-fit: cover;
    object-position: bottom right;
    width: 100%;
    height: 100%;
  }
`;

function ContentSlide({
  size,
  textBold,
  xBold,
  title,
  text,
  textasterisk,
  img,
  logo,
  icon,
  noBtn,
  linear,
  isLogged,
  link,
  scopri,
  imgMobile,
  left,
  dayCounterGame,
  partecipaText,
  textBtn,
  darkText,
  ...rest
}) {
  const token = useSelector(state => state.auth?.token);

  return (
    <Container className='' linear={linear} darkText={darkText} {...rest}>
      {imgMobile && img ? (
        <picture className='hiw-img'>
          <source media='(max-width: 768px)' srcSet={imgMobile} />
          <source media='(min-width: 769px)' srcSet={img} />
          <img src={img} alt='' />
        </picture>
      ) : (
        img && <img src={img} alt='' />
      )}
      <Text as='div' size={14} margin='0 ' bold={textBold} className='contentColor'></Text>
    </Container>
  );
}

export default ContentSlide;
