import React from 'react';
import PropTypes from 'prop-types';
import RouteGames from './RouteGames';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions';

const Games = props => {
  const {
    //general
    lang,
    multipromo,
    logo,
    // array games
    games,
    sendGameObj,
    // get code
    errorGetCode,
    codes,
    getCode,
    loadingGetCode,
    getCodeCleanError,
    cleanCodeFunction,
    //get ratephoto
    errorGetRatephoto,
    ratephotos,
    getRatephoto,
    loadingGetRatephoto,
    getRatephotoCleanError,
    cleanRatephotoFunction,
    //noprize
    getNoprize,
    noprize,
    correctAnsw,
    loadingNoprize,
    errorNoprize,
    getNoprizeCleanError,
    cleanNoprizeDataFunction,
    //get link
    getLink,
    links,
    linkRedirect,
    errorGetLink,
    //instant win
    getIw,
    iw,
    loadingIw,
    errorIw,
    getIwCleanError,
    cleanIwDataFunction,
    //save result game
    resultGame,
    loadingResultGame,
    errorResultGame,
    saveGameResult,
    saveGameResultCleanError,
    // auth
    isLogged,
    oauths,
    ownLogin,
    oauthInfo,
    //pdf
    regolamento,
    slug,
    setLocalid,
  } = props;

  let contentArray = [];
  let content = null;
  let action = {};

  let authObj = {
    isLogged,
    oauths,
    ownLogin,
    oauthInfo,
  };

  games.forEach(element => {
    switch (element.dynamic.type) {
      case 'getcode':
        action = {
          error: errorGetCode,
          data: codes,
          getDynamic: getCode,
          params: [],
          loading: loadingGetCode,
          cleanError: getCodeCleanError,
          cleanData: cleanCodeFunction,
        };
        break;
      case 'ratephoto':
        action = {
          error: errorGetRatephoto,
          data: ratephotos,
          getDynamic: getRatephoto,
          params: [],
          loading: loadingGetRatephoto,
          cleanError: getRatephotoCleanError,
          cleanData: cleanRatephotoFunction,
        };
        break;
      case 'getlink': //todo
        action = {
          error: errorGetLink,
          getLink,
          links,
          linkRedirect,
        };
        break;
      case 'instantwin':
        action = {
          error: errorIw,
          data: iw,
          getDynamic: getIw,
          params: [],
          loading: loadingIw,
          cleanError: getIwCleanError,
          cleanData: cleanIwDataFunction,
        };
        break;
      case 'default':
        action = {
          error: errorResultGame,
          data: resultGame,
          getDynamic: saveGameResult,
          params: [],
          loading: loadingResultGame,
          cleanError: saveGameResultCleanError,
        };
        break;
      case 'noprize':
        action = {
          error: errorNoprize,
          data: noprize,
          correctAnsw: correctAnsw,
          getDynamic: getNoprize,
          params: [],
          loading: loadingNoprize,
          cleanError: getNoprizeCleanError,
          cleanData: cleanNoprizeDataFunction,
        };
        break;
      default:
        action = {};
    }

    contentArray.push(
      <RouteGames
        lang={lang}
        key={element.id}
        authObj={authObj}
        g={element}
        played={element.played ? element.played : null}
        action={action}
        sendGameObj={sendGameObj}
        multipromo={multipromo}
        logo={logo}
        isLogged={isLogged}
        slug={slug}
        regolamento={regolamento}
        setLocalid={setLocalid}
      />,
    );
  });

  content = contentArray.map(a => {
    return a;
  });

  return <React.Fragment>{content}</React.Fragment>;
};

Games.propTypes = {
  games: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    loadingGetCode: state.game.loadingGetCode,
    errorGetCode: state.game.errorGetCode,
    codes: state.game.codes,
    loadingGetRatephoto: state.game.loadingGetRatephoto,
    errorGetRatephoto: state.game.errorGetRatephoto,
    ratephotos: state.game.ratephotos,
    links: state.game.links,
    linkRedirect: state.game.linkRedirect,
    errorGetLink: state.game.errorGetLink,
    iw: state.game.iw,
    noprize: state.game.noprize,
    correctAnsw: state.game.correctAnsw,
    loadingNoprize: state.game.loadingNoprize,
    errorNoprize: state.game.errorNoprize,
    loadingIw: state.game.loadingIw,
    errorIw: state.game.errorIw,
    resultGame: state.game.resultGame,
    loadingResultGame: state.game.loadingResultGame,
    errorResultGame: state.game.errorResultGame,
    logo: state.app.config.logo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCode: (id, slug, section, resultData, sid) =>
      dispatch(actionCreators.getCode(id, slug, section, resultData, sid)),
    getCodeCleanError: id => dispatch(actionCreators.getCodeCleanError(id)),
    cleanCodeFunction: id => dispatch(actionCreators.cleanCodeFunction(id)),
    getRatephoto: (id, slug, section, resultData, sid) =>
      dispatch(actionCreators.getRatephoto(id, slug, section, resultData, sid)),
    getRatephotoCleanError: id => dispatch(actionCreators.getRatephotoCleanError(id)),
    cleanRatephotoFunction: id => dispatch(actionCreators.cleanRatephotoFunction(id)),
    getLink: (id, slug, section, resultData) =>
      dispatch(actionCreators.getLink(id, slug, section, resultData)),
    getIw: (id, slug, section, resultData, sid, prizes, predictData, poc_id, lat, lng) =>
      dispatch(
        actionCreators.getIw(
          id,
          slug,
          section,
          resultData,
          sid,
          prizes,
          predictData,
          poc_id,
          lat,
          lng,
        ),
      ),
    getNoprize: (id, slug, section, resultData, sid) =>
      dispatch(actionCreators.getNoprize(id, slug, section, resultData, sid)),
    cleanIwDataFunction: id => dispatch(actionCreators.cleanIwDataFunction(id)),
    cleanNoprizeDataFunction: id => dispatch(actionCreators.cleanNoprizeDataFunction(id)),
    getNoprizeCleanError: () => dispatch(actionCreators.getNoprizeCleanError()),

    getIwCleanError: () => dispatch(actionCreators.getIwCleanError()),
    saveGameResult: (id, result) => dispatch(actionCreators.saveGameResult(id, result)),
    saveGameResultCleanError: () => dispatch(actionCreators.saveGameResultCleanError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Games);
