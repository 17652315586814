import React, { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch, useHistory, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Modal } from '../components/Ui';
import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';
import customAxios from '../config/axios-refresh-token';
import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';
import NotFound from '../views/NotFound';
import FormIsAdult from '../views/FormIsAdult/FormIsAdult';
import RegisterForm from '../components/RegisterForm/RegisterForm';
import ResetPasswordForm from '../components/ResetPasswordForm/ResetPasswordForm';
import RegisterFormSuccess from '../components/RegisterForm/RegisterFormSuccess';
import { Button } from '../components/Ui';
import { logout } from '../store/actions';
import Game from '../views/Game/Game';
import FormWin from '../views/FormWin/FormWin';
// const { refreshToken } = useSelector(state => state.auth || {});
const refresh_token = localStorage.getItem('refresh-token');
const UserActivated = React.lazy(() => import('../views/UserActivated'));
const Initiative = React.lazy(() => import('../views/Initiative'));
const Login = React.lazy(() => import('../views/Login'));
const Profile = React.lazy(() => import('../views/Profile'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
const RecoveryPassword = React.lazy(() => import('../views/RecoveryPassword'));

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  const history = useHistory();

  if (!rest.isLogged && history.location.pathname !== '/') {
    localStorage.setItem('redirectUrl', history.location.pathname);
  }
  const [isAdult, setIsAdult] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('isAdult')) {
      setIsAdult(true);
    }
  }, [isAdult]);

  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : isAdult ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          <AuthLayout {...props} cmp={FormIsAdult} onSetIsAdult={setIsAdult} />
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin } = props;

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path='/'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path='/auth/login'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={Login}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/auth/register'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={RegisterForm}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/auth/register-success'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={RegisterFormSuccess}
            ownLogin={ownLogin}
          />
          <Route
            path='/auth/recovery-password'
            exact
            render={route => (
              <AuthLayout cmp={RecoveryPassword} isLogged={props.isLogged} {...route} />
            )}
          />
          <Route
            path='/reset-password/:onetimetoken'
            exact
            render={route => (
              <AuthLayout cmp={ResetPassword} isLogged={props.isLogged} {...route} />
            )}
          />
          <Route
            path='/activate/:id'
            exact
            render={route => (
              <AuthLayout cmp={UserActivated} isLogged={props.isLogged} {...route} />
            )}
          />

          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/form-win'
            exact
            component={FormWin}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          {/* <Route path='/cognito' render={route => <AuthLayout cmp={Login} {...route} />} /> */}
          <Route render={() => <BaseLayout cmp={NotFound} />} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin.active,
  };
};

export default connect(mapStateToProps)(Routes);
