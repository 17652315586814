import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../theme/mixin';

import { Text } from '../../components/Ui';
import AuthNav from '../../components/AuthNav/AuthNav';

const AuthContentWrapper = ({ navActive, title, children }) => {
  return (
    <AuthContent>
      <Text bold size='18' className='mb-10'>
        PARTECIPA SUBITO!
      </Text>
      <AuthNav active={navActive} />

      {children}
    </AuthContent>
  );
};

const AuthContent = styled.div`
  padding: 0 15px;
  max-width: 570px;
  margin: auto;
  margin-bottom: 30px;

  ${respondTo.sm`
    flex: 0 0 400px;

    .signInContainer {
      margin-bottom: 0;
    }
  `}
`;

AuthContentWrapper.propTypes = {
  title: PropTypes.string,
  navActive: PropTypes.string,
};

export default AuthContentWrapper;
