import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .flex-inputs {
    display: flex;
    align-items: center;
    > label {
      width: 50%;
    }
  }
  label {
    display: block;
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      z-index: 200;
    }

    & input:checked ~ .checkmark {
      background-color: ${props => props.theme.primary};
    }

    & .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      border: 1px solid
        ${props =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : props.theme.input.bg};

      background-color: ${props => props.theme.input.bg};
    }
  }
`;

const RadioCustom = props => {
  const { radioInputs } = props;

  return (
    <Container onChange={props.onChange} {...props}>
      <div className='flex-inputs'>
        {radioInputs.map((radio, indx) => {
          return (
            <label htmlFor={props.id} key={indx}>
              {radio.label}
              <input {...props.elementConfig} value={radio.value} id={radio.id} />
              <span className='checkmark'></span>
            </label>
          );
        })}
      </div>
    </Container>
  );
};

export default RadioCustom;
