import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Prize from '../Prize';
import Form from '../../Form';
import ResultDynamic from '../ResultDynamic';
import { Button, Col, Box, Flex, Text } from '../../../Ui';
import Fade from '../../../Fade/Fade';
import { getElement } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
import LeftBoxCardHandler from '../../Handlers/CardHandler/LeftBoxCardHandler';
import emptyBox from '../../../../assets/images/boxLoose.png';
import ThankYouPage from '../../Handlers/ThankYouPage/ThankYouPage';
import leffeCombinations from '../../../../assets/images/leffe-combinations.png';
import loosegame from '../../../../assets/images/loosegame.png';
import CustomWrapper from '../../../Ui/CustomWrapper/CustomWrapper';
import { respondTo } from '../../../../theme/mixin';
const ResultGame = props => {
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      check: { attemptslimit },
      log: { steps, code, error, prize, form: formResponse, playslog: playslog_id, formend },
      i18l,
      dynamic: { type },
      form,
    },
    lang,
    played,
    action,

    action: { cleanData },
    click,
  } = props;

  const [isFormOpen, setIsFormOpen] = useState(false);
  useEffect(() => {
    // window.scrollTo(0, 0);
    return () => {
      cleanData(id);
    };
  }, []);

  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};
  let correctResponse = action.correctAnsw;
  let errorNoprize = action.errorNoprize;
  let correct_answ = null;
  if (played) {
    theData = steps.interaction.data;
  } else {
    correct_answ = correctResponse;

    theData = theProfileData.data ? theProfileData.data : null;
  }

  let rulesData = rules ? JSON.parse(rules) : null;
  let theCodeData = {};

  if (theProfileData) {
    theCodeData = {
      code: theProfileData.code,
      error: theProfileData.error,
    };
  } else {
    theCodeData = { code: code, error: error };
  }

  let playslog = theProfileData ? theProfileData.playslog : log.playslog;

  let thePrize = theProfileData ? theProfileData.prize : log.prize;
  const lastIwImage = useSelector(state => state.game.lastIw.prize_image);
  const lastIwPrize = useSelector(state => state.game.lastIw.prize);
  const lastIwPrizeRes = useSelector(state => state.game.lastIw);

  const lastShowPrizeForm = useSelector(state => state.game.lastIw.show_prize_form);

  const isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
  let won = !isEmpty(lastIwPrizeRes) ? lastIwPrizeRes.won : props.g.log.won;

  let userWon = theProfileData ? theProfileData.won : won;
  if (lastIwPrize) {
    thePrize = lastIwPrize;
  }

  const wingame = lastIwImage ? lastIwImage : log.prize_image;
  const show_prize_form = !isEmpty(lastIwPrizeRes) ? lastShowPrizeForm : log.show_prize_form;
  let resultDynamic = null;
  if (attemptslimit && type === 'noprize') {
    resultDynamic = 'info';
  } else if (userWon) {
    resultDynamic = 'win';
  } else {
    resultDynamic = 'loose';
  }
  const formendTouse = !isEmpty(lastIwPrizeRes) && show_prize_form ? false : formend;
  let dynamicData = i18l[resultDynamic];
  const checkFormCompiled = useSelector(state => state.game.formCompiled);
  const checkFormPrizeAccepted = useSelector(state => state.game.formCompiledPrizeAccepted);

  return (
    <Container className='-mt-20' click={click}>
      <div className='content'>
        {type === 'noprize' ? (
          <ThankYouPage correct_answ={correct_answ} errorNoprize={errorNoprize} {...props} />
        ) : won || theProfileData?.won ? (
          <ResultDynamic
            dynamicData={dynamicData}
            thePrize={thePrize || {}}
            // extra={thePrize.extra || null}
            wingame={wingame}
            checkFormCompiled={checkFormCompiled}
            checkFormPrizeAccepted={checkFormPrizeAccepted}
            playslog_id={action?.data[0]?.playslog || playslog_id}
            id_game={id}
            show_prize_form={show_prize_form}
            click
            form={form}
            formend={formendTouse}
            attemptslimit={attemptslimit}
            slug={props.slug}
            lang={props.lang}
            id={id}
          />
        ) : (
          <div className='loose'>
            <img src={click ? loosegame : emptyBox} alt='' />

            <div className='content-prize'>
              <Text loose size={18} upper bold style={{ ...(click && { padding: '0 15px ' }) }}>
                {i18l?.loose?.title}
              </Text>
              <Text size={16} style={{ ...(click && { padding: '0 15px 15px 15px' }) }}>
                {i18l?.loose?.content}
              </Text>
            </div>
          </div>
        )}
        <div className=''>
          <ContainerSuggestion>
            <img src={leffeCombinations} alt='' width={86} />
            <div className='suggestion-text'>
              <Text bold size={14} upper>
                PROVA GLI ABBINAMENTI APPROVATI DA CHEF VALBUZZI
              </Text>
              <Text size={14}>
                <strong>Esalta il sapore di Leffe </strong>con gli accostamenti migliori, proprio{' '}
                <strong>come faresti con il vino!</strong>
              </Text>
            </div>
          </ContainerSuggestion>
          <div className='mx-15 '>
            <Button fullwidth active upper bold className='mb-20 mt-20'>
              <a href='https://leffe.it/abbinamenti?b=5' target='_blank' rel='noopener noreferrer'>
                SCOPRI TUTTI GLI ABBINAMENTI
              </a>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  .container-result-img {
    position: relative;
    .text-result {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translateY(-50%);
    }
  }
  .content-prize {
    padding: 0 15px;
    .inputs-refuse-accept {
      label {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  .content {
    width: 100%;
    position: relative;

    ${respondTo.sm`
  height: 100%;
    max-width: 770px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  `}
    /*   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    } */
    .loose {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .content-cta {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 40px;
    }
  }
`;
const ContainerSuggestion = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 40px;
  width: 100%;
  .suggestion-text {
    padding: 21px 15px;
    background: white;
    flex-grow: 1;
  }
`;
export default ResultGame;
