import React, { useEffect, useState } from 'react';
import { Flex, Button, Text, Logo, Wrapper } from '../../../Ui';
import { Link } from 'react-router-dom';
import Fade from '../../../Fade/Fade';
import { connect, useSelector } from 'react-redux';
import { getSlug } from '../../../../utils/utils';
import Seo from '../../../Seo';
import * as actionCreators from '../../../../store/actions';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ContentSlide from '../../../ContentSlide';
import { Input } from '../../../Ui';
import bannerHome from '../../../../assets/images/bannerHome.png';
import imgMobile from '../../../../assets/images/homeMobile.png';
import imgGlass from '../../../../assets/images/calice.svg';
import rotateCamera from '../../../../assets/images/flip.png';
import CustomWrapper from '../../../Ui/CustomWrapper/CustomWrapper';
import { choiceLocal } from '../../../../formsConfig/formsConfig';
import Webcam from 'react-webcam';
import useForm from '../../../../hooks/useForm';
import GeoLocationChecker from '../../../GeolocationChecker/GeolocationChecker';
import customAxios from '../../../../config/axios-refresh-token';
import { useRef } from 'react';
import { Modal, Loader } from '../../../Ui';
import customaxios from '../../../../config/axios-refresh-token';
import moment from 'moment';
import { use } from 'i18next';
const WebcamContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 20000000;
  top: 0;
  overflow: hidden;
  .btn-black-local {
    position: absolute;
    bottom: 135px;
    left: 20px;
    background: white;
    color: black;
    z-index: 99999999999999999;
  }
  img {
    position: absolute;
    top: calc((100% - 90px) / 2);
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(80% - 90px);
    &.rotatecamera {
      top: unset;
      bottom: 100px;
      right: 20px;
      left: unset;
      z-index: 99999999999999999;
    }
  }
  .bg-black {
    width: 100%;
    background: black;

    height: 100%;
  }
  video {
    object-fit: cover;
    width: 100%;
    height: calc(100% - 90px);
  }
  .container-btn-camera {
    background: #fff4e0;
    height: 90px;
    padding: 30px 15px;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
`;

const ConcorsoGame = props => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    isLogged,
  } = props;

  const idealWidth = window.innerWidth;
  const idealHeight = window.innerHeight - 90;
  const [showModalErrorPredict, setShowModalErrorPredict] = useState(false);
  const [loadingPlay, setLoadingPlay] = useState(false);
  const [showWebcam, setShowWebcam] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const webcamRef = useRef(null);

  const lat = localStorage.getItem('lat');
  const lng = localStorage.getItem('lng');
  const poc_id = localStorage.getItem('local_id');
  let distance = localStorage.getItem('distance');

  const [showModalLastPlay, setShowModalLastPlay] = useState(false);
  const [showModalLastAttempt, setShowModalLastAttempt] = useState(false);

  const minutesBetweenPlay = useSelector(state => state.game?.games?.[0]?.minutes_between_plays);
  const gameAttempts = useSelector(state => state.promo?.game?.[0]?.check?.attempts.day);
  const [myGameAttempts, totalGameAttempts] = gameAttempts.split('/').map(Number);
  const lastPlayDate = useSelector(state => state.promo?.game?.[0]?.next_allowed_play);
  const canPlay = useSelector(state => state.promo?.game?.[0]?.can_play);
  const [loadingCamera, setLoadingCamera] = useState(true);
  useEffect(() => {
    localStorage.setItem('lasturl', window.location.href);
  }, []);

  const gotStream = stream => {
    setShowWebcam(true);
    window.stream = stream;
    webcamRef.current.srcObject = stream;
    setLoadingCamera(false);
    return navigator.mediaDevices.enumerateDevices();
  };
  const titleModalAttempts = `HAI GIÀ GIOCATO ${totalGameAttempts} VOLTE!`;
  const gotDevices = deviceInfos => {
    const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
    const backCamera = deviceInfos.filter(
      camera =>
        camera.label.toLowerCase().includes('back') ||
        camera.label.toLowerCase().includes('rear') ||
        camera.label.toLowerCase().includes('arrière') ||
        camera.label.toLowerCase().includes('posteriore') ||
        camera.label.toLowerCase().includes('trasera'),
    );
    let deviceId = '';
    let cameraPreferred = null;
    if (backCamera.length > 0) {
      cameraPreferred = backCamera.find(
        camera =>
          camera.label.toLowerCase().includes('camera2 0') ||
          camera.label.toLowerCase().includes('fotocamera (posteriore)') ||
          camera.label.toLowerCase().includes('caméra arrière') ||
          camera.label.toLowerCase().includes('back camera') ||
          camera.label.toLowerCase().includes('càmara trasera'),
      );
      deviceId = cameraPreferred ? cameraPreferred.deviceId : backCamera[0].deviceId;
    } else {
      deviceId = videoDevices[0].deviceId;
    }

    if (!selectedVideo) {
      setSelectedVideo(deviceId);
    } else {
      if (selectedVideo == videoDevices[0].deviceId) {
        setSelectedVideo(deviceId);
      } else {
        setSelectedVideo(videoDevices[0].deviceId);
      }
    }
  };

  const checkLocal = () => {
    customAxios
      .post(`/app/checkposition`, {
        poc_id: poc_id,
        lat: lat,
        lng: lng,
        distance: distance,
      })
      .then(response => {
        if (response.data.success === true && response.status === 200) {
          if (lastPlayDate) {
            const lastPlayMoment = moment(lastPlayDate);
            const currentMoment = moment();
            if (myGameAttempts === totalGameAttempts && totalGameAttempts !== 0 && !canPlay) {
              setShowModalLastAttempt(true);
            } else if (lastPlayMoment.isAfter(currentMoment) && !canPlay) {
              setShowModalLastPlay(true);
            } else {
              openWebcam();
            }
          } else {
            if (myGameAttempts === totalGameAttempts && totalGameAttempts !== 0) {
              setShowModalLastAttempt(true);
            } else {
              openWebcam();
            }
          }
        } else {
          props.setLocalid(false);
          localStorage.removeItem('local_id');
        }
      })
      .catch(error => {});
  };

  useEffect(() => {
    setLoadingCamera(true);
    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);
  }, []);

  const handleError = error => {
    console.log('error: ', error.message, error.name);
  };

  const openWebcam = () => {
    setLoadingCamera(true);

    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    const constraints = {
      video: {
        deviceId: selectedVideo ? { exact: selectedVideo } : undefined,
        width: { ideal: idealWidth },
        height: { ideal: idealHeight },
      },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .then(gotDevices)
      .catch(handleError);
  };

  const base64toFile = (base64, fileName, mimeType) => {
    const base64WithoutHeader = base64.replace(/^data:image\/[a-z]+;base64,/, '');
    const byteCharacters = atob(base64WithoutHeader);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  };

  const submitDataHandler = (data, predict_id) => {
    const dataToSend = {};
    dataToSend.type = 'receipt';
    const sid = props.g.log.sid;

    data.append('data', JSON.stringify(dataToSend));
    data.append('sid', sid);
    data.append('predict_id', predict_id);
    data.append(
      'section',
      props.typeInteraction === 'preinteraction' ? 'preinteraction' : 'interaction',
    );

    customaxios
      .post(`game/${props.slug}/${props.g.id}/data`, data)
      .then(response => {
        const { data } = response;
        if (response.status === 200) {
          if (props.typeInteraction === 'preinteraction') {
            if (sid) {
              props.onShowInteractionHandler();
              props.setPredictData(predict_id);
            } else {
              props.onShowInteractionHandler();
              props.setPredictData(predict_id);
            }
          }
        }
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          // Handle error
        }
      });
  };

  const capturePhoto = () => {
    setLoadingPlay(true);
    const canvas = document.createElement('canvas');
    canvas.width = webcamRef.current.videoWidth;
    canvas.height = webcamRef.current.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(webcamRef.current, 0, 0, canvas.width, canvas.height);
    const imageSrc = canvas.toDataURL('image/jpeg');
    const fileName = 'glassImage.jpeg';
    const mimeType = 'image/jpeg';
    const imageFile = base64toFile(imageSrc, fileName, mimeType);
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('poc_id', localStorage.getItem('local_id'));
    customAxios
      .post(`/ai/predict`, formData)
      .then(response => {
        if (!response.data.success) {
          console.log('error');
          setShowModalErrorPredict(true);
          setShowWebcam(false);
          setLoadingPlay(false);
        } else {
          let predict_id = response.data.predict_id;
          submitDataHandler(formData, predict_id);
          setShowWebcam(false);
          setLoadingPlay(false);
        }
      })
      .catch(error => {
        setShowModalErrorPredict(true);
        setShowWebcam(false);
        setLoadingPlay(false);
      });
  };

  return (
    <div>
      {showWebcam ? (
        <div>
          <WebcamContainer>
            <img alt='' src={imgGlass} />
            <img
              alt=''
              onClick={openWebcam}
              className='rotatecamera'
              src={rotateCamera}
              width='50'
            />
            <Button
              active
              upper
              bold
              type='button'
              className='btn-black-local'
              onClick={() => {
                props.setLocalid(false);
                localStorage.removeItem('local_id');
              }}
            >
              Indietro
            </Button>
            {loadingCamera && <div className='bg-black'></div>}

            <video audio='false' playsInline autoPlay ref={webcamRef}></video>

            <div className='container-btn-camera'>
              <Button
                fullwidth
                active
                upper
                bold
                type='button'
                disabled={loadingPlay}
                onClick={capturePhoto}
              >
                {loadingPlay ? <Loader show={true} /> : 'Scatta Foto'}
              </Button>
            </div>
          </WebcamContainer>
        </div>
      ) : (
        <div>
          <ContentSlide img={bannerHome} imgMobile={imgMobile} />
          {isLogged && (
            <Wrapper className=''>
              <CustomWrapper>
                <>
                  <Text size={18} bold>
                    È IL TUO MOMENTO!
                  </Text>
                  <Text className='mb-20' size={16} light>
                    Inquadra frontalmente il tuo calice di Leffe ancora pieno e appoggiato sul
                    tavolo, scatta la foto e prova a vincere!
                  </Text>
                  <Button
                    fullwidth
                    active
                    upper
                    bold
                    type='button'
                    className='mb-lg-20'
                    onClick={checkLocal}
                  >
                    gioca
                  </Button>
                </>
                <Flex className='mt-20' align='center' gap='10px'>
                  <Text size={16} light>
                    Vuoi cambiare locale?
                  </Text>
                  <Button
                    active
                    upper
                    bold
                    type='button'
                    className='btn-black-local flex-grow-1'
                    onClick={() => {
                      props.setLocalid(false);
                      localStorage.removeItem('local_id');
                    }}
                  >
                    Clicca qui
                  </Button>
                </Flex>
              </CustomWrapper>
            </Wrapper>
          )}
        </div>
      )}

      <Modal
        show={showModalErrorPredict}
        title='OPS, C’È UN PROBLEMA CON LA FOTO!'
        close={() => setShowModalErrorPredict(false)}
        zindex={true}
      >
        <Text size={16} className='mb-20'>
          Ti ricordiamo che devi scattare la foto al tuo calice di Leffe ricordandoti che:
        </Text>
        <Text size={16} className='mb-20'>
          • la Leffe deve esserti stata servita nell’iconico calice;
        </Text>
        <Text size={16} className='mb-20'>
          • il calice deve essere pieno almeno per metà;
        </Text>
        <Text size={16} className='mb-20'>
          • l’illuminazione della foto deve consentire di distinguere chiaramente il calice e il suo
          contenuto;
        </Text>
        <Text size={16} className='mb-20'>
          • il calice deve essere poggiato sul tavolo.
        </Text>
        <Button
          fullwidth
          active
          upper
          bold
          onClick={() => setShowModalErrorPredict(false)}
          style={{ marginBottom: 15 }}
        >
          Riprova
        </Button>
      </Modal>
      <Modal
        show={showModalLastPlay}
        title='HAI APPENA GIOCATO!'
        close={() => setShowModalLastPlay(false)}
        zindex={true}
      >
        <Text size={16} className='mb-20'>
          Ti ricordiamo che tra un tentativo e l’altro devono passare {minutesBetweenPlay} minuti.
          Riprova tra un po’.
        </Text>
      </Modal>
      <Modal
        show={showModalLastAttempt}
        title={titleModalAttempts}
        close={() => setShowModalLastAttempt(false)}
        zindex={true}
      >
        <Text size={16} className='mb-20'>
          Hai raggiunto il limite massimo giornaliero di giocate. Riprova domani.
        </Text>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    logo: state.app.config.logo,
    appConfig: state.app.config,
    homeCms: state.cms.home,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConcorsoGame));
