import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Col, Flex, Text } from '../../../Ui';
import BoxLayout from '../../../Ui/BoxLayout';
import Ratephoto from '../../Interactions/Ratephoto/Ratephoto';
import { ReactComponent as IconGrazie } from '../../../../assets/images/grazie.svg';
import { respondTo } from '../../../../theme/mixin';
import { useSelector } from 'react-redux';
import Shares from '../../../Shares';

const CustomCol = styled(Col)`
  .section-heading > span {
    color: #fff;
  }
  img {
    width: 100%;
    ${respondTo.sm` 
    
   
   `};
  }
`;
const CustomCol1 = styled(Col)`
  .svg {
    width: 100%;
    height: 60px;
    ${respondTo.sm` 
     width:20%;
     height: 95px;
   `};
  }
  .text {
    width: 100%;

    ${respondTo.sm` 
     height:100%;
     width:70%;
 
    display:flex;
flex-direction:column;
justify-content:space-between;
   `};
  }
  a {
    ${respondTo.sm` 
   display: block;
    margin: 20px 0;
   `};
  }
  button {
    margin: 0 auto;
    padding: 0 20px;
    ${respondTo.sm` 
     margin:initial;
     padding:0 50px;
   `};
  }
`;
const SocialShareContainer = styled.div`
  margin-top: 20px;
`;
const UploadReceiptHandler = props => {
  const {
    g: {
      id,
      ratephoto: { image, uploaded, status },
      i18l: { win, loose },
    },
  } = props;
  const { title: winTitle, content: winContent } = win || {};
  const { title: loseTitle, content: loseContent } = loose || {};
  const description = useSelector(state => state.promo?.promoinfo?.i18l?.viewer?.description);
  const title = useSelector(state => state.promo?.promoinfo?.i18l?.viewer?.title);
  const pdf = useSelector(state => state.promo?.promoinfo?.pdf_regolamento);
  const subtitle = useSelector(state => state.promo?.promoinfo?.i18l?.subtitle);
  const [showResult, setShowResult] = useState(false);

  const onShowResult = () => {
    setShowResult(true);
  };
  let metatags = {
    title: winTitle,
    description: winContent,
    url: window.location.href,
    image: image,
    type: 'website',
  };
  return (
    <React.Fragment>
      {showResult ? (
        <div>result</div>
      ) : (
        <Flex>
          <CustomCol1 width={description ? 60 : 100} style={{ flexGrow: 1, padding: '0 10px' }}>
            {uploaded ? (
              <BoxLayout
                title={status !== 'pending' ? winTitle : loseTitle}
                width='100%'
                style={{ flex: 1, borderRadius: '10px', height: '100%' }}
              >
                <Flex gap='10px' style={{ height: '100%' }}>
                  <IconGrazie className='svg' />
                  <div className='text'>
                    <Text size={14}>{status !== 'pending' ? winContent : loseContent}</Text>

                    {status === 'approved' && (
                      <SocialShareContainer>
                        <Shares socialShare={['facebook', 'instagram']} metatags={metatags} />
                      </SocialShareContainer>
                    )}

                    <Link
                      to='/concorsi/mettiti-in-sposa'
                      style={{
                        marginTop: status === 'pending' ? '15px' : '30px',
                        display: 'block',
                      }}
                    >
                      <Button active>TORNA ALLA HOME PAGE</Button>
                    </Link>
                  </div>
                </Flex>
              </BoxLayout>
            ) : (
              <Ratephoto onShowResult={onShowResult} pdf={pdf} {...props} />
            )}
          </CustomCol1>

          <CustomCol padding={10} className='sm_mt-20 ' stype={{ flexGrow: 1 }} width={38}>
            {uploaded ? (
              <img src={image} style={{ borderRadius: '10px' }} alt='' />
            ) : (
              description && (
                <Flex align='center' direction='column' style={{ height: '100%' }}>
                  <BoxLayout
                    title={title}
                    width='100%'
                    style={{ flex: 1, borderRadius: '10px' }}
                    bgColor='#0051CE'
                  >
                    <Text size={14} white>
                      {description}
                    </Text>

                    <Link to='/contact' style={{ marginTop: '30px', display: 'block' }}>
                      <Button hoverWhite>SERVE AIUTO?</Button>
                    </Link>
                  </BoxLayout>
                </Flex>
              )
            )}
          </CustomCol>
        </Flex>
      )}
    </React.Fragment>
  );
};

export default UploadReceiptHandler;
