import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTransition, useChain, animated } from 'react-spring';
import closeModalImg from '../../../assets/images/closeModal.svg';
import closeModalMobileImg from '../../../assets/images/closemodalmobile.svg';
import { Button, Text } from '../';
import { respondTo } from '../../../theme/mixin';
const Overlay = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  z-index: 100000000;
`;

const Content = styled(animated.div)`
  width: 90%;
  max-width: 570px;
  background-color: ${props => (props.theme.modal ? props.theme.modal : '#fff')};
  padding: 20px 15px 20px 15px;
  position: absolute;

  top: 50%;
  &.flex-item {
    top: 100px;
  }
  z-index: 998;
  border: 2px solid ${props => (props.type === 'error' ? '#E90000' : 'transparent')};
  color: ${props => props.theme.text.primary};
  box-shadow: 0px 5px 15px #00000026;
  ${respondTo.sm`
  
  padding: 40px 15px 20px 15px;
	`}
`;

const ModalContainer = styled.div`
  position: relative;
  &.flex-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
  display: none;
  ${({ closeModal }) => closeModal && 'display:none'};
  ${respondTo.sm`
    display:block;
	`}
`;
const CloseModalMobile = styled.div`
  position: fixed;
  top: 22px;
  right: 16px;
  z-index: 999;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  ${({ closeModal }) => closeModal && 'display:none'};
  ${respondTo.sm`
    display:none;
	`}
`;

const Modal = props => {
  const { close, children, show, type, closeModal, hideCloseIcon, flex, title, zindex } = props;

  const overlayTransitionRef = useRef();
  const overlayTransition = useTransition(show, null, {
    ref: overlayTransitionRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const modalTransitionRef = useRef();
  const modalTransition = useTransition(show, null, {
    ref: modalTransitionRef,
    unique: true,
    trail: 100,
    from: { opacity: 0, transform: 'translateY(-4%)' },
    enter: { opacity: 1, transform: 'translateY(-50%)' },
    leave: { opacity: 0, transform: 'translateY(-4%)' },
  });

  useChain(
    show ? [overlayTransitionRef, modalTransitionRef] : [modalTransitionRef, overlayTransitionRef],
    [0, show ? 0.1 : 0.6],
  );
  return overlayTransition.map(({ item, key, props }) =>
    item ? (
      <Overlay key={key} style={props}>
        {!hideCloseIcon && (
          <CloseModalMobile onClick={close} className='closeModal' closeModal={closeModal}>
            <img src={closeModalMobileImg} alt='' />
          </CloseModalMobile>
        )}
        {modalTransition.map(({ item, key, props }) =>
          item ? (
            <Content
              key={key}
              style={props}
              type={type}
              className={`contentModal ${flex ? 'flex-item' : ''}`}
            >
              <Text upper bold className='fs-lg-30 mb-lg-20'>
                {title}
              </Text>
              {!hideCloseIcon && (
                <CloseModal onClick={close} className='closeModal' closeModal={closeModal}>
                  <img src={closeModalImg} alt='' />
                </CloseModal>
              )}
              <ModalContainer className={flex ? 'flex-item' : ''}>
                {children}
                {type === 'error' && (
                  <Button secondary onClick={close} style={{ marginTop: 20, width: 200 }}>
                    Ok
                  </Button>
                )}
              </ModalContainer>
            </Content>
          ) : null,
        )}
      </Overlay>
    ) : null,
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  show: PropTypes.bool,
};

export default Modal;
