import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { resetPasswordForm } from '../../formsConfig/formsConfig';
import { getOneTimeToken } from '../../utils/utils';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { Button, Input, Loader, Modal, Typography, SmallLink } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { Text } from '../Ui';
import { Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import { DieFive } from 'styled-icons/foundation';
const AuthContent = styled.div`
  padding: 0 15px;
  max-width: 570px;
  margin: auto;
  margin-bottom: 30px;

  ${respondTo.sm`
    flex: 0 0 400px;

    .signInContainer {
      margin-bottom: 0;
    }
  `}
`;
const AccediBtnContainer = styled.div`
  margin-top: 20px;
`;

const AccediSmallBtnContainer = styled.div`
  margin-top: 16px;
`;

const LoaderContainer = styled.div`
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResetPasswordForm = props => {
  const inputRef = useRef();

  const {
    resetPassword,
    reset,
    errorReset,
    loadingReset,
    clearError,
    resetChangePassword,
    loadingChangePsw,
    changePsw,
    errorChangePsw,
    clearChangeError,
  } = props;

  const [showModal, setShowModal] = useState(true);
  const [showErrorChangeModal, setShowErrorChangeModal] = useState(false);

  const [t] = useTranslation();
  const resetForm = resetPasswordForm(
    t('auth.insertnewpsw'),
    t('auth.confirmnewpsw'),
    t('auth.pswerror'),
    t('auth.pswconfirmerror'),
    inputRef,
  );

  useEffect(() => {
    resetPassword(getOneTimeToken());
  }, []);

  useEffect(() => {
    if (reset) {
      inputRef.current.focus();
    }
  }, [reset]);

  useEffect(() => {
    if (errorChangePsw !== null) {
      setShowErrorChangeModal(true);
    }
  }, [errorChangePsw]);

  const resetFormSubmit = () => {
    resetChangePassword(formData.password.value, formData.confirmpassword.value, getOneTimeToken());
  };

  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const modalChangeErrorHandler = () => {
    setShowErrorChangeModal(false);
    clearChangeError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit, showError } = useForm(
    resetFormSubmit,
    resetForm,
  );

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
      />
    );
  });

  return (
    <AuthContent>
      {1 == 1 ? (
        changePsw ? (
          <>
            <Text upper bold className='fs-lg-30'>
              GRAZIE, LA TUA PASSWORD È STATA CAMBIATA.
            </Text>
            <AccediBtnContainer>
              <Button type='submit' fullwidth upper active bold>
                <Link to='/auth/login' align='center'>
                  Torna al login
                </Link>
              </Button>
            </AccediBtnContainer>
          </>
        ) : (
          <>
            <Text upper bold className='fs-lg-30  mb-20'>
              {' '}
              SCEGLI UNA NUOVA PASSWORD
            </Text>

            <form onSubmit={formSubmitHandler}>
              {inputs}
              <div>
                <Button type='submit' fullwidth upper active bold>
                  {loadingChangePsw ? <Loader inBtn show={true} /> : 'Cambia password'}
                </Button>
              </div>
            </form>
          </>
        )
      ) : loadingReset ? (
        <LoaderContainer>
          <Loader show={loadingReset} />
        </LoaderContainer>
      ) : (
        <>
          <Modal show={showModal} close={modalHandler}>
            <Text upper bold className='fs-lg-30'>
              <ErrorInModal error={errorReset} fromTranslation />
            </Text>
          </Modal>
          <AccediBtnContainer>
            <Link to='/auth/recovery-password'>
              <Button fullwidth>{t('auth.recoverypswlabel')}</Button>
            </Link>
          </AccediBtnContainer>
        </>
      )}
      <Modal show={showErrorChangeModal} close={modalChangeErrorHandler}>
        <Text upper bold className='fs-lg-30'>
          <ErrorInModal error={errorChangePsw} fromTranslation />
        </Text>
      </Modal>
    </AuthContent>
  );
};

const mapStateToProps = state => {
  return {
    reset: state.auth.reset,
    errorReset: state.auth.errorReset,
    loadingReset: state.auth.loadingReset,
    loadingChangePsw: state.auth.loadingChangePsw,
    changePsw: state.auth.changePsw,
    errorChangePsw: state.auth.errorChangePsw,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: onetimetoken => dispatch(actionCreators.resetPassword(onetimetoken)),
    clearError: () => dispatch(actionCreators.resetPasswordClearError()),
    resetChangePassword: (psw, confirmpsw, onetimetoken) =>
      dispatch(actionCreators.resetChangePassword(psw, confirmpsw, onetimetoken)),
    clearChangeError: () => dispatch(actionCreators.resetChangePasswordClearError()),
  };
};

ResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func,
  reset: PropTypes.bool,
  errorReset: PropTypes.number,
  clearError: PropTypes.func,
  resetChangePassword: PropTypes.func,
  loadingChangePsw: PropTypes.bool,
  changePsw: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
