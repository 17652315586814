import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '../index';
import { respondTo } from '../../../theme/mixin';
import { useSpring, animated } from 'react-spring';

const ViewerContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  ${respondTo.sm`
    display: block;
  `}
`;

const ViewerRelative = styled.div`
  position: relative;
  max-width: 75rem;
  margin: 0 auto;
  flex-basis: 100%;
  order: 2;
`;

const ViewerBackground = styled(animated.div)`
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url(${props => props.imgmobile});
  clip-path: ${props =>
    props.theme.isrect
      ? 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
      : 'polygon(0 0, 100% 0, 100% 100%, 0 90%)'};
  width: 100%;
  height: 305px;
  flex-basis: 100%;
  order: 1;
  margin-bottom: 20px;
  position: relative;

  ${respondTo.sm`
    background-image: url(${props => props.imgdesktop});
    height: 541px; /* 475px */
    margin-bottom: 0px;
  `}
`;

const ViewerContent = styled(animated.div)`
  position: static;
  order: 2;
  top: 50px;
  left: 0.9375rem;
  width: 100%;
  box-sizing: border-box;
  z-index: 9;
  padding: 0 0.9375rem;

  ${respondTo.sm`
    position: absolute; 
    order: 1;
    padding: 0;
    width: calc(100% - 100px);
`}
`;

const TextContainer = styled.div`
  max-width: 100%;

  ${respondTo.sm`
    max-width: 60%;
  `}

  ${respondTo.md`
    max-width: 50%;
  `}
`;
const TitleContainer = styled.div`
  margin-bottom: 10px;

  ${respondTo.sm`
    margin-bottom: 20px;
  `}
`;

const ButtonContainer = styled.div`
  margin: 30px 0 0 0;
`;

const LogoPromo = styled.div`
  margin-top: 40px;
  max-width: 100px;
  display: none;

  ${respondTo.sm`
    display: block;
  `}
`;

const LogoPromoMobile = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  max-width: 100px;
  display: block;

  ${respondTo.sm`
    display: none;
  `}
`;

const Viewer = props => {
  const {
    scream,
    description,
    type,
    islogged,
    logo,
    logopromo,
    url,
    cta,
    iscta,
    ctatype,
    dark_text,
    oauths,
    ownlogin,
    oauthinfo,
    idgame,
    pathname,
    multipromo,
  } = props;

  const style = useSpring({
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    from: { opacity: 0, transform: 'translate3d(0,8px,0)' },
  });

  const style2 = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  return (
    <ViewerContainer>
      <ViewerRelative>
        <ViewerContent style={style}>
          <TextContainer>
            <TitleContainer>
              <Typography as='h1' type='vscream' fl dark_text={dark_text} switchcolor>
                {scream}
              </Typography>
            </TitleContainer>
            <Typography
              as='p'
              type='vbody'
              switchcolor
              dark_text={dark_text}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </TextContainer>

          {logo ? (
            <LogoPromo>
              <img src={logopromo} alt='' />
            </LogoPromo>
          ) : null}
        </ViewerContent>
      </ViewerRelative>
      <ViewerBackground style={style2} {...props}>
        {logo ? (
          <LogoPromoMobile>
            <img src={logopromo} alt='' />
          </LogoPromoMobile>
        ) : null}
      </ViewerBackground>
    </ViewerContainer>
  );
};

Viewer.propTypes = {
  scream: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  logopromo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  iscta: PropTypes.number,
  ctatype: PropTypes.string,
};

export default Viewer;
