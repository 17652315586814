import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import { Text } from '../../Ui';

import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import Logout from '../../Logout/Logout';
import LinkDownload from './NavigationItem/LinkDownload/LinkDownload';
import { Link } from 'react-router-dom';

const NavUl = styled.ul`
  list-style: none;

  .last-child {
    margin-right: 0;
  }
  .cart-container {
    position: relative;
    display: flex;
    justify-content: center;
    .cart-img {
      background: #002d72;
      width: 29px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .cart-numb {
      position: absolute;
      top: 0px;
      right: -6px;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      span {
        color: white;
        font-size: 7px;
        border: 0;
      }
      @media (max-width: 1024px) {
        top: 20%;
        left: 52%;
        transform: translateX(-50%);
      }
    }
  }

  ${respondTo.md`
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
	`}

  .only-desktop {
    display: none;

    ${respondTo.md`
      display: block;
    `}
  }

  .only-mobile {
    display: block;

    &.logout {
      border-top: 1px solid #dce4e8;
      padding: 15px 0;
    }

    ${respondTo.md`
      display: none;
    `}
  }
`;

const NavigationItems = props => {
  const {
    isLogged,
    multipromo,
    i18l,
    idGame,
    isOpen,
    logout,
    webview,
    refresh_token,
    setShowModalLogout,
  } = props;

  const [t] = useTranslation();

  return (
    <NavUl isOpen={isOpen} onClick={props.clicked}>
      {isLogged && (
        <>
          <NavigationItem link='/' active='true'>
            <Text bold size={12}>
              HomePage
            </Text>
          </NavigationItem>
          <NavigationItem link='/user/profile' active='true'>
            <Text bold size={12}>
              {t('header.profile')}
            </Text>
          </NavigationItem>

          <div className='no-desk py-10'>
            <div className='logout' onClick={() => setShowModalLogout(true)}>
              <Logout label={t('auth.logoutlabel')} />
            </div>
          </div>
        </>
      )}

      {/* {i18l.nav?.menu_static_links
        ? i18l.nav?.menu_static_links.map((item, i) => {
            return (
              <NavigationItem link={item.url} active='true' key={i}>
                <Text>{item.label}</Text>
              </NavigationItem>
            );
          })
        : null} */}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
