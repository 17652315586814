import React from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useTranslation } from 'react-i18next';
import { Flex, Col, Text } from '../../components/Ui';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { respondTo } from '../../theme/mixin';
import InputField from '../../components/Ui/Input/InputField/InputField';
import iconProfile from '../../assets/images/iconProfile.svg';
import moment from 'moment';
const ProfileBlock = props => {
  const { userinfo } = useSelector(state => state.user?.user || {});
  return (
    <MyDataProfile>
      <Flex className=' mb-lg-20' justify='space-between' align='center'>
        <Text xBold='900' upper className='fs-lg-30'>
          I tuoi dati
        </Text>
        <Flex wrap='nowrap' align='center' gap='9px'>
          <img src={iconProfile} alt='' />
          <Text xBold='600' upper className='fs-lg-14' semibold>
            Campi non modificabili
          </Text>
        </Flex>
      </Flex>

      <div className='container-fields-profile first-block'>
        <Flex className='mb-lg-20 flex-lg-nowrap'>
          <Col padding='0' className='sm_mb-10 lg_me-30'>
            <InputField value={userinfo?.firstname} disabled />
          </Col>
          <Col padding='0' className=''>
            <InputField value={userinfo?.lastname} disabled />
          </Col>
        </Flex>
        <Flex wrap='wrap' className='mb-10 flex-lg-nowrap'>
          <Col padding='0' className='sm_mb-10 lg_me-30'>
            <InputField value={userinfo?.email} disabled />
          </Col>
          <Col padding='0'>
            <InputField value={moment(userinfo?.dob).format('DD/MM/YYYY')} disabled />
          </Col>
        </Flex>
      </div>
    </MyDataProfile>
  );
};
const MyDataProfile = styled.div`
  background-color: ${({ theme }) => theme.bgPrimary};
  padding: 20px 15px;
  margin-top: -20px;
  margin-left: -15px;
  margin-right: -15px;
  ${respondTo.sm` 
    padding: 30px 15px;
    margin-top: initial;
    margin-left: 0;
    margin-right: 0;
    `}
  .container-fields-profile {
    * {
      color: ${({ theme }) => theme.yellow};

      &.title-fields {
        font-size: 16px;
        color: ${({ theme }) => theme.primary};
        ${respondTo.sm` 
          font-size: 18px;
          `}
      }
    }
  }
`;

export default ProfileBlock;
