import moment from 'moment';
import { generateDays, generateYears } from '../utils/utils';
import { useSelector } from 'react-redux';

export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Username',
        ref,
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};

export const registrationform = (
  firstname,
  lastname,
  pswerror,
  pswconfirmerror,
  email,
  emailconfirmerror,
  dateofbirth,

  ref,
) => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Cognome',
      },
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: ``,
      value: '',
      validation: {
        toMatch: true,
        isEmail: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    repeatemail: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'repeatemail',
        id: 'repeatemail',
        placeholder: 'Conferma Email',
      },
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
        isEmail: true,
        isConfirmEmail: true,
      },
      errorMessage: `${emailconfirmerror}`,
      valid: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      label: ``,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: 'Conferma password',
      },
      label: ``,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      label: ``,
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    first_time_leffe: {
      elementType: 'inputradiocheck',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'first_time_leffe',
        id: 'first_time_leffe',
      },
      label: 'È LA PRIMA VOLTA CHE ASSAGGI LEFFE?',
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      inputs: [
        {
          id: 'first_time_leffe_accept',
          label: `SI`,
          value: 1,
        },
        {
          id: 'first_time_leffe_reject',
          label: `NO`,
          value: 0,
        },
      ],
    },
    accept_rules: {
      elementType: 'inputcheckboxslide',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accept_rules',
        id: 'accept_rules',
      },
      label: `Dichiaro di aver letto e accettato il &nbsp;<a style="text-transform: initial" target="_blank" href="${process.env.PUBLIC_URL}/pdf/regolamento.pdf">regolamento</a>.`,
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_privacy: {
      elementType: 'inputcheckboxslide',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label: `<p>Dichiaro di aver letto e accettato l\'<a style="text-transform: initial" target="_blank" href="${process.env.PUBLIC_URL}/pdf/PrivacyPolicyLeffe.pdf">informativa sulla privacy</a></p>`,
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    not_worker: {
      elementType: 'inputcheckboxslide',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'not_worker',
        id: 'not_worker',
      },
      label: `Dichiaro di non essere titolare, collaboratore e/o dipendente della Società Promotrice.`,
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_marketing: {
      elementType: 'inputcheckboxslide',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: `Autorizzo il trattamento dei miei dati personali per finalità di comunicazioni e informazioni commerciali previste dall’<a href="${process.env.PUBLIC_URL}/pdf/PrivacyPolicyLeffe.pdf" style="text-transform: initial" target="_blank"><strong>informativa privacy</strong></a>`,
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },

    personalization: {
      elementType: 'inputcheckboxslide',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'personalization',
        id: 'personalization',
      },
      label: ` Accetto il trattamento dei dati personali per finalità di profilazione delle preferenze.`,
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
        ref,
      },
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const changePasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  pswwrong,
  ref,
) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: `Password attuale`,
        ref,
      },
      value: '',
      validation: {
        isPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `Nuova password`,
        ref,
      },
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: `Ripeti nuova password`,
      },
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const resetPasswordForm = (newPswLabel, confirmNewPsw, pswerror, pswconfirmerror, ref) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: `${newPswLabel}`,
        ref,
      },
      label: '',
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `${confirmNewPsw}`,
      },
      label: '',
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const profileform = user => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: 'Nome',
      value: user.firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.firstname ? true : false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: 'Cognome',
      value: user.lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.lastname ? true : false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: 'Email',
      value: user.email,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.email ? true : false,
    },
    code: {
      elementType: 'input',
      isCode: true,
      elementConfig: {
        type: 'text',
        name: 'code',
        id: 'code',
        placeholder: 'N° tessera',
      },
      label: 'N° tessera',
      value: user.code || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.code ? true : false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: 'Indirizzo',
      value: user.address,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.address ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Provincia', disabled: true }],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: user.district || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.district ? true : false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: user.city || '',
            displayValue: user.city || 'Città',
            disabled: true,
          },
        ],
        name: 'city',
      },
      label: 'Città',
      value: user.city || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.city ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: 'CAP',
      value: user.zipcode || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.zipcode ? true : false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: 'Cellulare',
      value: user.phone,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.phone ? true : false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      lang: 'it',
      label: 'Data di nascita',
      value: moment(user.dob).format('DD/MM/YYYY') || '',
      valuetosend: moment(user.dob).format('L') || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.dob ? true : false,
    },
  };
};

export const choiceLocal = () => {
  return {
    local_id: {
      elementType: 'select',
      isLocal: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Seleziona il locale*', disabled: true }],

        name: 'local_id',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
export const concorsoForm = ({ maxDate, pdf }) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
    photo_title: {
      elementType: 'input',
      isInvited_by: true,
      elementConfig: {
        type: 'text',
        name: 'photo_title',
        id: 'photo_title',
        placeholder: '',
      },
      label: 'TITOLO FOTO',
      value: '',
      validation: {
        required: false,
        touched: false,
        maxLength: 150,
      },
      valid: false,
    },
    photo_date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: '',
        name: 'photo_date',
      },
      label: 'INSERISCI LA DATA DEL MATRIMONIO',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        minDate: new Date('07/01/2024'),
        maxDate: new Date('11/30/2024'), //mm-dd-yyyy or today
      },
      valid: false,
    },
    store: {
      elementType: 'select',
      isStore: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: '',
            disabled: true,
          },
        ],
        placeholder: 'Seleziona un atelier',
        name: 'store',
      },
      label: 'SELEZIONA UN ATELIER',
      value: '',

      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<span>Dichiaro di avere in programma di sposarmi tra il 01/07/2024 ed entro il 30/11/2024
      (compresi)</span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'privacy',
        id: 'privacy',
      },
      label: `<span>Dichiaro di aver letto e accettato il <a href='${process.env.PUBLIC_URL}' target='_blank'>regolamento</a> </span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
