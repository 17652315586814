import React from 'react';
import styled from 'styled-components';

const TextareaField = styled.textarea`
  outline: none;
  width: 100%;
  background-color: ${props => props.theme.input.bg};
  border: 2px solid transparent;
  overflow: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  height: 150px;
  color: ${props => props.theme.primary};
  font-family: ${props => props.theme.fontFamily}, sans-serif;

  &::placeholder {
    color: #47545d59;
  }
`;

const Textarea = props => {
  return <TextareaField {...props} />;
};

export default Textarea;
