import React from 'react';
import { useState } from 'react';
import { choiceLocal } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import { Input, Text, Button, Wrapper } from '../Ui';
import GeoLocationChecker from '../GeolocationChecker/GeolocationChecker';
import { Loader } from '../Ui';
import CustomWrapper from '../Ui/CustomWrapper/CustomWrapper';
const GeolocationForm = ({ localid, setLocalid }) => {
  const geolocationForm = choiceLocal();

  const finalForm = { ...geolocationForm };
  const geolocationLocal = data => {
    localStorage.setItem('local_id', data.local_id);
    setLocalid(data.local_id);
  };
  const geolocationSubmit = () => {
    const data = {};

    for (const property in formData) {
      data[property] = formData[property].value;
    }
    geolocationLocal(data);
  };
  const [showTabInfoGeolocation, setShowTabInfoGeolocation] = useState(false);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    setLocals,
    locals,
    showError,
  } = useForm(geolocationSubmit, finalForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  const showTabInfoGeolocFunction = () => {
    showTabInfoGeolocation ? setShowTabInfoGeolocation(false) : setShowTabInfoGeolocation(true);
  };
  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isLocal={inp.isLocal}
        locals={locals}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        {...inp}
      />
    );
  });
  return (
    <Wrapper className=''>
      <CustomWrapper>
        <Text bold size={18} className='mb-10'>
          PREPARATI A GIOCARE…
        </Text>
        <Text size={16} light className='mb-20'>
          Seleziona il locale dal quale stai giocando.
        </Text>
        <form onSubmit={formSubmitHandler}>
          <GeoLocationChecker showError={showError} setLocals={setLocals} />
          {inputs}

          <Button fullwidth active upper bold type='submit' disabled={false} className='mb-20'>
            {false ? <Loader inBtn show={true} /> : 'Continua'}
          </Button>
        </form>
        <Text
          size={16}
          bold
          className='text-underlined'
          onClick={() => showTabInfoGeolocFunction()}
        >
          Hai problemi con la geolocalizzazione?
        </Text>
        {showTabInfoGeolocation ? (
          <Text className='mb-20' size='16'>
            Se hai problemi con la geolocalizzazione, verifica che le impostazioni del tuo
            smartphone siano corrette. In particolar modo:
            <br />- se hai un <strong>iPhone</strong>: Impostazioni > Privacy e sicurezza >
            Localizzazione > Seleziona il browser che stai utilizzando (Safari, Chrome, Siti web di
            Safari) > selezionare “Mentre usi l’app” o “Chiedi la prossima volta o quando condivido”{' '}
            <br /> - se hai uno smartphone con
            <strong> Android</strong>: Impostazioni > Posizione > Attivato e poi Impostazioni >
            Sicurezza e Privacy > Privacy > Gestione autorizzazioni > Posizione > Verifica che il
            tuo browser (Chrome, Internet Samsung, ecc) sia autorizzato.
            <br />
            Al termine di queste verifiche, tornare sulla pagina di gioco,{' '}
            <strong>aggiornare la pagina</strong> e cliccare su "ATTIVA LA GEOLOCALIZZAZIONE".
          </Text>
        ) : (
          ''
        )}
      </CustomWrapper>
    </Wrapper>
  );
};
export default GeolocationForm;
