import React, { useState } from 'react';
import styled from 'styled-components';
import InputField from './InputField/InputField';
import Select from './Select/Select';
import Checkbox from './Checkbox/Checkbox';
import CheckboxSlide from './CheckboxSlide/CheckboxSlide';
import Textarea from './Textarea/Textarea';
import Datepicker from './Datepicker';
import File from './File';
import Radio from './Radio';
import RadioCustom from './RadioCustom';
import InputPrice from './InputPrice';
import InputTime from './InputTime';
import moment from 'moment';
import errormsg1 from '../../../assets/images/error_msg1.png';
import errormsg2 from '../../../assets/images/error_msg2.png';
import { respondTo } from '../../../theme/mixin';
const InputContainer = styled.div`
  margin-bottom: 20px;
  display: ${props => (props.type === 'checkbox' ? 'block' : 'block')};
  .error_img {
    margin-bottom: -5px;
    img {
      max-width: 120px;
      ${respondTo.sm`
        max-width: 200px;
      `}
    }
  }
`;

const ErrorContainer = styled.p`
  margin-top: 5px;
  text-align: right;
  color: #921a28;
  font-size: 12px;
`;

const TextP = styled.p`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  margin-top: 24px;
`;

const Input = props => {
  let label = props.label;
  let inputElement = null;
  let title = null;

  const [startDate, setStartDate] = useState(null);

  if (props.title) {
    title = <TextP {...props}>{props.title}</TextP>;
  }
  let error_msg = errormsg1;
  switch (props.elementType) {
    case 'input':
      inputElement = (
        <InputField
          {...props.elementConfig}
          onChange={props.changed}
          {...props}
          value={props.value}
          readOnly={props.readonly}
        />
      );
      break;

    case 'inputrange':
      inputElement = (
        <div className='range-container'>
          <div className='select-container'>
            {props.isRange ? (
              <Select
                {...props.elementConfig}
                required={!props.isStore}
                onChange={props.changed}
                elementType={props.elementType}
                disabled={props.elementConfig.disabled}
                className='select-contact'
                {...props}
              >
                {props.elementConfig.options.map(opt => (
                  <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                    {opt.displayValue}
                  </option>
                ))}
              </Select>
            ) : (
              <InputField
                {...props.elementConfig}
                {...props.validation}
                onChange={props.changed}
                {...props}
                value=''
                min={0}
                max={props.maxValue}
              />
            )}
          </div>
        </div>
      );

      break;
    case 'select':
      let options = null;

      if (props.isDistrict) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}

            {props.districts.map(opt => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.elementConfig.placeholder === 'Provincia') {
        options = (
          <>
            {props.elementConfig.options?.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.provinces?.map(opt => (
              <option value={opt.value} key={opt.id}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isCity) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.cities.map(opt => (
              <option value={opt.name} key={opt.name}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isStore) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.id} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.stores.map(opt => (
              <option value={opt.pdv} key={opt.pdv}>
                {opt.pdv}
              </option>
            ))}
          </>
        );
      } else if (props.isLocal) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.id} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.locals.map(opt => (
              <option value={opt.player_id} key={opt.player_id}>
                {opt.pub_name}
              </option>
            ))}
          </>
        );
      } else {
        options = props.elementConfig.options.map(opt => (
          <option value={opt.value} key={opt.value}>
            {opt.displayValue}
          </option>
        ));
      }
      inputElement = (
        <Select
          onChange={props.changed}
          name={props.elementConfig.name}
          disabled={props.elementConfig.disabled}
          className='select-contact'
          {...props}
        >
          {options}
        </Select>
      );
      break;
    case 'datepicker':
      inputElement = (
        <Datepicker
          lang={props.lang}
          {...props.elementConfig}
          selected={props.startdate}
          onChange={props.changedDatePicker}
          {...props}
        />
      );
      break;
    case 'inputcheckbox':
      error_msg = errormsg2;
      inputElement = (
        <Checkbox
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          onClick={() => (props.readonly ? 'return false;' : '')}
          {...props}
        />
      );
      break;
    case 'inputcheckboxslide':
      error_msg = errormsg2;
      inputElement = (
        <CheckboxSlide
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          onClick={() => (props.readonly ? 'return false;' : '')}
          {...props}
        />
      );
      break;
    case 'inputradio':
      inputElement = (
        <Radio
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          inputs={props.radioInputs}
          readonly={props.readonly}
          {...props}
        />
      );
      break;
    case 'inputradiocheck':
      inputElement = (
        <RadioCustom
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          inputs={props.radioInputs}
          readonly={props.readonly}
          {...props}
        />
      );
      break;
    case 'inputfile':
      inputElement = (
        <File
          {...props.elementConfig}
          handleFile={props.fileUploadHandler}
          imagePreviewUrl={props.imagePreviewUrl}
          deleteFileHandler={props.deleteFileHandler}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'price':
      inputElement = (
        <InputPrice
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'inputtime':
      inputElement = (
        <InputTime
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <Textarea
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    default:
      inputElement = <input />;
  }
  if (props.elementConfig.type === 'hidden') {
    error_msg = '';
  }
  return (
    <React.Fragment>
      {title}
      <InputContainer
        type={props.elementConfig.type}
        className={
          props.elementConfig?.type == 'checkbox ' ? 'check_contact' + props.name : props.name
        }
      >
        {error_msg && (
          <div className='error_img'>
            {(!props.isvalid && !props.istouched && props.firstSubmit && props.showError) ||
            (!props.isvalid && props.showError) ? (
              <img src={error_msg} alt='' width={200} />
            ) : (
              ''
            )}{' '}
          </div>
        )}

        <label
          className={
            props.elementConfig?.type === 'radio' ? 'radio label-contact' : 'label-contact '
          }
        >
          {label && props.elementConfig?.type !== 'checkbox' ? label : null}
        </label>
        {inputElement}
        <ErrorContainer>
          {(!props.isvalid && !props.istouched && props.firstSubmit && props.showError) ||
          (!props.isvalid && props.showError)
            ? props.error
            : ''}
        </ErrorContainer>
      </InputContainer>
    </React.Fragment>
  );
};

export default Input;
