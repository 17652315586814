import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '../../components/Fade';
import Form from '../../components/Games/Form';
import { getGame, getGames, getUserInfo } from '../../store/actions';
import { useHistory } from 'react-router';
import CustomWrapper from '../../components/Ui/CustomWrapper/CustomWrapper';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Flex, Box, Text, Wrapper, Col, Button } from '../../components/Ui';
import iconProfile from '../../assets/images/iconProfile.svg';
import InputField from '../../components/Ui/Input/InputField/InputField';
import ProfileBlock from '../Profile/ProfileBlock';
function FormWin() {
  const dispatch = useDispatch();
  const history = useHistory();
  //const isLogged = useSelector(state => state.auth.token);
  const { userinfo } = useSelector(state => state.user?.user || {});
  const formInfo = useSelector(state => state.user.formInfo || {});
  const loadingForm = useSelector(state => state.user.loadingForm);
  const lang = useSelector(state => state.app.config?.lang);
  //const { game } = useSelector(state => state.promo || {});
  //const { log, played, form } = game.length > 0 ? game[0] : {};

  useEffect(() => {
    //dispatch(getGame(wins[0]?.game_id, 'test-click', ''));
    return () => {
      dispatch(getUserInfo());
    };
  }, []);

  useEffect(() => {
    /*    if (wins?.length > 0) {
      wins.every(({ pending }) => !pending) && history.push('/');
    } */
  }, []);
  //const { form, id_game, playslog_id, promotion, game: gameName, prize } = formInfo || {};
  return (
    <div className='-mt-20'>
      <Wrapper>
        <CustomWrapper>
          <ProfileBlock />
          {formInfo?.form ? (
            <Form lang={lang} {...formInfo} />
          ) : (
            <Text style={{ paddingTop: '20px' }}> Il form non esiste</Text>
          )}
        </CustomWrapper>
      </Wrapper>
    </div>
  );
}

export default FormWin;
