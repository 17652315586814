import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import { lineHeight, fontSize, fontStyle, size } from 'styled-system';
import { theme } from '../../../theme/theme';
const TextContainer = styled.span`
  display: block;
  font-style: ${props => (props.italic ? 'italic' : '')};
  ${({ size }) =>
    size
      ? css`
          font-size: ${size}px;
        `
      : fontSize};
  font-weight: ${props =>
    props.xBold ? props.xBold : props.bold ? '700' : props.light ? '300' : '400'};
  text-align: ${props => props.align};
  color: ${props =>
    props.color
      ? props.color
      : props.primary
      ? props.theme.primary
      : props.white
      ? props.theme.default_colortext
      : props.theme.default_color};
  ${({ clamp }) =>
    clamp &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  &:first-letter {
    text-transform: ${props => (props.capit ? 'capitalize' : props.upper)};
  }
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};

  width: ${props => props.width};
  margin: ${props => props.margin};
  ul {
    padding-left: 20px;
  }
`;

const Text = props => {
  const textStyles = theme.textStyles;
  return (
    <TextContainer {...props} {...textStyles[props.type || 'textDetails']}>
      {typeof props.children === 'number'
        ? props.children
        : /<\/?[a-z][\s\S]*>/i.test(props.children)
        ? parse(props.children) || ''
        : props.children}
    </TextContainer>
  );
};

/* Text.defaultProps = {
  size: 16,
  align: 'left',
}; */

Text.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  align: PropTypes.string,
  white: PropTypes.bool,
  bold: PropTypes.bool,
  light: PropTypes.bool,
  upper: PropTypes.bool,
};

export default Text;
