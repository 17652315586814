import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { registrationform } from '../../formsConfig/formsConfig';
import { Button, SmallLink, Input, Loader, Modal, Typography, Text } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { respondTo } from '../../theme/mixin';
import { useHistory } from 'react-router';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthContentWrapper from '../../components/AuthContentWrapper/AuthContentWrapper';
const RegisterForm = props => {
  const {
    signUpInit,
    loadingSignup,
    signup,
    clearError,
    error,
    lang,
    own_login_adult,
    fields,
    extrafields,
    gdpr,
    codefield: { label, visible, required, regex },
    notes,
  } = props;

  const history = useHistory();
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }
  if (props.isLogged) {
    window.location.href = '/';
  }
  let code = {};
  if (visible) {
    code = {
      code: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'code',
          id: 'code',
          placeholder: '',
        },
        label: `${label}`,
        value: '',
        validation: {
          isCustomCode: regex ? true : false,
          regex: regex,
          required: required,
          touched: false,
        },
        valid: !required,
        labelColorSecondary: true,
      },
    };
  }

  const [showModal, setShowModal] = useState(false);
  const [t] = useTranslation();
  const inputRef = useRef();
  const registerForm = registrationform(
    t('auth.firstname'),
    t('auth.lastname'),
    t('auth.pswerror'),
    t('auth.pswconfirmerror'),
    t('auth.email'),
    t('auth.emailconfirmerror'),
    t('auth.dateofbirth'),
    gdpr,
    t('gdpr.accept'),
    t('gdpr.reject'),
  );
  const registerFormfiltered = Object.keys(registerForm)
    .filter(key => fields.includes(key))
    .reduce((obj, key) => {
      obj[key] = registerForm[key];
      return obj;
    }, {});
  let sortedObj = {};
  fields.forEach(item => {
    sortedObj[item] = registerFormfiltered[item];
  });
  const finalForm = { ...registerFormfiltered, ...code, ...JSON.parse(extrafields) };

  const register = () => {
    const data = {};

    if (visible && code !== {}) {
      fields.push('code');
    }

    for (const property in formData) {
      data[property] =
        property !== 'dob' ? formData[property].value : formData[property].valuetosend;
    }

    const fieldsData = Object.keys(data)
      .filter(key => fields.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const otherFields = Object.keys(data)
      .filter(key => !fields.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const dataToSend = {
      ...fieldsData,
    };

    const client_id = getCookie('_td') ? getCookie('_td') : '';
    const url = window.location.href;
    const host = window.location.host;
    dataToSend['td_client_id'] = client_id;
    dataToSend['td_url'] = url;
    dataToSend['td_host'] = host;
    signUpInit(dataToSend);
  };

  const modalHandler = () => {
    setShowModal(!showModal);
    clearError();
  };

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
    return () => {};
  }, [error]);

  useEffect(() => {
    if (signup) {
      history.push('/auth/register-success');
    }
  }, [signup]);

  let datePickerNames = [];
  for (var prop in finalForm) {
    if (finalForm[prop].elementType === 'datepicker') {
      datePickerNames.push(finalForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    districts,
    cities,
    zipcode,
  } = useForm(register, finalForm, datePickerNames);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        own_login_adult={own_login_adult}
        radioInputs={inp.inputs}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        {...inp}
      />
    );
  });

  return (
    <Wrapper isSelect={false}>
      <form id='Leffe_raddoppiailgusto_2024-Q1' onSubmit={formSubmitHandler}>
        <AuthContentWrapper navActive='register'>
          <InputsWrapper>{inputs}</InputsWrapper>

          <div>
            <Button active upper fullwidth bold disabled={loadingSignup}>
              {loadingSignup ? <Loader inBtn show={true} /> : 'Registrati'}
            </Button>
          </div>
        </AuthContentWrapper>
      </form>

      <Modal show={showModal} close={modalHandler} type='error'>
        <ErrorInModal error={error} fromTranslation={typeof error === 'number' ? true : false} />
      </Modal>
    </Wrapper>
  );
};
const InputsWrapper = styled.div`
  .flex-input {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      width: calc(50% - 10px);
    }
  }
  .label-contact.radio {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  form {
    max-width: 570px;
    margin: auto;
  }
`;
const ProgressDots = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;

  & > div {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    border: solid 1px ${props => props.theme.yellow};

    &.active {
      background-color: ${props => props.theme.yellow};
    }
  }
`;

const mapStateToProps = state => {
  return {
    loadingSignup: state.auth.loadingSignup,
    signup: state.auth.signup,
    error: state.auth.errorSignup,
    lang: state.app.config.lang,
    own_login_adult: state.app.config.ownlogin.adult,
    fields: state.app.config.ownlogin.fields,
    codefield: state.app.config.ownlogin.code,
    extrafields: state.app.config.ownlogin.extrafields,
    notes: state.app.config.ownlogin.notes,
    gdpr: state.app.config.ownlogin.gdpr,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUpInit: data => dispatch(actionCreators.signUpInit(data)),
    clearError: () => dispatch(actionCreators.signupClearError()),
  };
};

RegisterForm.propTypes = {
  signUpInit: PropTypes.func,
  loadingSignup: PropTypes.bool,
  signup: PropTypes.bool,
  clearError: PropTypes.func,
  error: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
