import React from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';

const FlipCard = styled.div`
  background-color: transparent;
  width: 100px;
  height: 100px;
  perspective: 1000px;
  display: inline-block;
  margin-bottom: 20px;
  cursor: pointer;
  ${props =>
    props.isCompleted &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
  ${respondTo.med`
        width: 120px;
        height: 120px;
		margin: 8px;
		
    `};
  ${respondTo.sm`
        width: 140px;
        height: 140px;
		margin: 8px;
		
    `};
  ${respondTo.md`
        width: 150px;
        height: 150px;
		margin: 8px;
		
    `};
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  ${props =>
    props.isOpen &&
    css`
      transform: rotateY(180deg);
      cursor: default;
    `};
`;

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: transparent;
  color: black;
  z-index: 2;
  border-radius: 8px;
  border: 4px solid white;
  background-image: ${props => (props.brand ? `url(${props.brand})` : ``)};
  background-size: cover;
  ${props =>
    props.isCompleted &&
    css`
      display: none;
    `};
`;

const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-image: ${props => `url(${props.src})`};
  color: black;
  transform: rotateY(180deg);
  z-index: 1;
  border-radius: 8px;
  border: 4px solid white;
  background-size: cover;
`;

const Card = props => {
  const { clicked } = props;

  return (
    <FlipCard {...props} onClick={clicked}>
      <FlipCardInner {...props}>
        <FlipCardFront {...props} />
        <FlipCardBack {...props} />
      </FlipCardInner>
    </FlipCard>
  );
};

export default Card;
