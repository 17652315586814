import { createGlobalStyle } from 'styled-components';
import { respondTo } from '../../theme/mixin';
const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 100%;
    height: 100%;
    color: ${props => props.theme.text_primary};
  }
  body {
    font-family: ${props => props.theme.fontFamily}, sans-serif;
    font-size: 100%;
    height: 100%;
    background: #FFF4E0;
    scroll-behavior: smooth;
    ${respondTo.sm`
      
    `}
  }
  main {
    padding-top: 70px;
    ${respondTo.sm`
    padding-top: 100px;
    `}
  }
  .text-underlined {
    text-decoration: underline;
  }

  .no-mobile {
    display: none;
    ${respondTo.sm`
      display: block;
    `}
  }
  .fs-lg-49 {
    font-size: 34px;
    ${respondTo.sm`
      font-size: 49px;
    `}
  }
  .fs-lg-30 {
    font-size: 19px;
    ${respondTo.sm`
      font-size: 30px;
    `}
  }
  .fs-lg-18 {
    font-size: 14px;
    ${respondTo.sm`
      font-size: 18px;
    `}
  }
  .fs-lg-16 {
    font-size: 14px;
    ${respondTo.sm`
      font-size: 16px;
    `}
  }
  .fs-lg-14 {
    font-size: 9px;
    ${respondTo.sm`
      font-size: 14px;
    `}
  }

  .fs-lg-22 {
    font-size: 16px;
    ${respondTo.sm`
      font-size: 22px;
    `}
  }

  .fs-lg-68 {
    font-size: 30px;
    ${respondTo.sm`
      font-size: 68px;
    `}
  }
  .pb-lg-30 {
    padding-bottom: 10px;
    ${respondTo.sm`
    padding-bottom: 30px;
    `}
  }
  .fw-bold {
    font-weight: 700;
  }
  .w-100 {
    width: 100%;
  }
  .max-w-570 {
    max-width: 570px;
    margin:auto;
  }
  .px-15 {
    padding: 15px;
  }
  button {
    font-family: ${props => props.theme.fontFamily}, sans-serif;
  }
  .py-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .no-desk {
    display: block;

    &.logout {
      border-top: 1px solid #dce4e8;
      padding: 15px 0;
    }

    ${respondTo.md`
      display: none;
    `}
  }
  .no-mobile {
    display: none;

    &.logout {
      border-top: 1px solid #dce4e8;
      padding: 15px 0;
    }

    ${respondTo.md`
      display: block;
    `}
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.secondary};
  }
  .react-datepicker__navigation {
    display: none;
  }
  .slick-list > div {
    margin-left: 0;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mx-15{
    margin-right: 15px;
    margin-left: 15px;
  } 
  .-mt-20 {
    top: -20px;
    position: relative;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .d-none {
    display: none;
  }
  .d-block {
    display: block
  }
  .mb-lg-20 {
    margin-bottom: 10px;
    ${respondTo.md`
      margin-bottom: 20px;
    `}
  }
  .mb-6 {
    margin-bottom: 6px;
  }
  .md_mb-10 {
    margin-bottom: 10px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .md_mb-20 {
    margin-bottom: 20px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .sm_mb-10 {
    margin-bottom: 10px;

    ${respondTo.sm`
      margin-bottom: 0;
    `}
  }
  .sm_mb-20 {
    margin-bottom: 20px;

    ${respondTo.sm`
      margin-bottom: 0;
    `}
  }
  .sm_mb-30 {
    margin-bottom: 30px;

    ${respondTo.sm`
      margin-bottom: 0;
    `}
  }
  .lg_me-30 {
    margin-right: 0;

    ${respondTo.sm`
      margin-right: 30px;
    `}
  }
  .sm_mt-20 {
    margin-top: 20px;

    ${respondTo.sm`
      margin-top: 0;
    `}
  }
  .sm_mt-15 {
    margin-top: 15px;

    ${respondTo.sm`
      margin-top: 0;
    `}
  }
  .md_mt-15 {
    margin-top: 15px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mb-30 {
    margin-bottom: 30px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }

  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .md_mt-10 {
    margin-top: 10px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mt-20 {
    margin-top: 20px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mt-30 {
    margin-top: 30px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .flex-lg-nowrap {
    flex-wrap: wrap;
    ${respondTo.sm`
      flex-wrap: nowrap;
    `}
  }
  .px-lg-15 {
    ${respondTo.sm`
    padding-left: 15px;
    padding-right: 15px;
  `}
    
  }
  .slick-track
    {
        display: flex !important;
    }

  .slick-slide
  {
    // margin:0 5px;
      height: inherit !important;
      & > div{
        height: 100%;
      }
  }
`;

export default GlobalStyle;
