import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Text } from '../Ui';
import ErrorInModal from '../ErrorInModal';

const SessionExpired = props => {
  const { isExpired } = props;

  const closeModalHandler = () => {
    window.location.reload();
  };

  return (
    <Modal show={isExpired} close={closeModalHandler}>
      <Text upper bold className='fs-lg-30'>
        <ErrorInModal error='sessionexpired' fromTranslation />
      </Text>
    </Modal>
  );
};

SessionExpired.propTypes = {
  isExpired: PropTypes.bool,
};

export default SessionExpired;
