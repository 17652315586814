import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  validateEmail,
  validatePassword,
  customRegexValidation,
  getDistricts,
  getCities,
  calculateAge,
  getCountries,
} from '../utils/utils';

import customAxios from '../config/axios-refresh-token';

const useForm = (callback, formState, datepickername, resetForm = false, isConvalida = false) => {
  const [formData, setFormData] = useState(formState);

  const [firstSubmit, setFirstSubmit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [control, setControl] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [startDate, setStartDate] = useState({ date: null, name: null });

  const [selectedFile, setSelectedFile] = useState(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [locals, setLocals] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [countries, setCountries] = useState([]);
  const [stores, setStores] = useState([]);
  const [problemOrderSelected, setProblemOrderSelected] = useState(false);

  const checkValidity = (value, rules, target) => {
    let isValid = true;

    if (rules.required) {
      isValid = value !== '' && isValid;
      if (target?.type === 'checkbox') {
        if (target?.checked == true) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
      if (target?.type === 'radio') {
        isValid = true;

        if (rules.requiredValue) {
          if (target?.value == rules.requiredValue) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
      }
      if (target?.type === 'file') {
        if (target?.value) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }
    if (rules.isEmail) {
      isValid = validateEmail(value) && isValid;
    }
    if (rules.isPassword) {
      isValid = validatePassword(value) && isValid;
    }
    if (rules.isConfirmPassword) {
      isValid = value === passwordValue && isValid;
    }
    if (rules.isAdult) {
      isValid = calculateAge(new Date(value)) && isValid;
    }
    if (rules.isConfirmEmail) {
      isValid = value === emailValue && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isCustomCode) {
      isValid = customRegexValidation(rules.regex, value) && isValid;
    }
    return isValid;
  };

  const inputChangedHandler = event => {
    const updateFormData = {
      ...formData,
    };

    if (event.target.name === 'district') {
      setCities([]);
      getCities(event.target.value).then(data => {
        setCities(data);
      });
    }

    if (event.target.name === 'province_id') {
      setCities([]);
      getCities(event.target.options[event.target.selectedIndex].getAttribute('data-name')).then(
        data => {
          setCities(data);
        },
      );
    }

    if (event.target.name === 'city') {
      let zipcode = cities.filter(city => city.name === event.target.value)[0].zipcode;
      setZipcode(zipcode);
      updateFormData['zipcode'].valid = true;
      updateFormData['zipcode'].value = cities.filter(
        city => city.name === event.target.value,
      )[0].zipcode;
    }

    if (event.target.name === 'city_id') {
      let zipcode = cities.filter(city => city.id === +event.target.value)[0].zipcode;
      setZipcode(zipcode);
      updateFormData['zipcode'].valid = true;
      updateFormData['zipcode'].value = cities.filter(
        city => city.id === +event.target.value,
      )[0].zipcode;
    }

    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.value =
      event.target.type === 'checkbox' ? (event.target.checked ? '1' : '0') : event.target.value;

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target,
    );
    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;

    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid && formIsValid;
    }
    if (updateFormDataElement.validation.toMatch) {
      if (updateFormDataElement.validation.isPassword) {
        setPasswordValue(updateFormDataElement.value);
      }

      if (updateFormDataElement.validation.isEmail) {
        setEmailValue(updateFormDataElement.value);
      }
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };

  const resetFormHandler = () => {
    setFirstSubmit(false);
    const updateFormData = {
      ...formData,
    };

    for (let i in updateFormData) {
      if (updateFormData[i].elementType === 'inputfile') {
        if (updateFormData[i].valid === true) {
          updateFormData[i].valid = true;
        }
      } else if (updateFormData[i].elementType === 'inputcheckboxslide') {
        updateFormData[i].value = 0;
        updateFormData[i].valid = true;
      } else if (updateFormData[i].elementType === 'input') {
        updateFormData[i].value = '';
        updateFormData[i].valid = true;
      } else if (updateFormData[i].elementType === 'inputrange') {
        updateFormData[i].value = '';
        updateFormData[i].valid = true;
      } else if (updateFormData[i].elementType === 'select') {
        updateFormData[i].value = '';
        updateFormData[i].valid = true;
      } else {
        updateFormData[i].value = '';
        updateFormData[i].valid = false;
      }
      if (updateFormData[i].elementConfig.name === 'order_id') {
        setProblemOrderSelected(false);
      }
    }
    setFormData(updateFormData);
  };

  const formSubmitHandler = event => {
    event.preventDefault();
    setFirstSubmit(true);
    // if (control) {
    setShowError(true);
    // }
    let formDataObj = {};
    for (let formEl in formData) {
      if (formData[formEl].valuetosend) {
        formDataObj[formEl] = formData[formEl].valuetosend;
      } else {
        formDataObj[formEl] = formData[formEl].value;
      }
    }

    if (isConvalida && formData['prize']?.value === '2') {
      callback(formDataObj);
    }

    if (formIsValid) {
      callback(formDataObj);
    }
  };

  const datePickerHandler = (date, name) => {
    setStartDate({ date, name });
  };

  const fileUploadHandler = event => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file !== undefined) {
      setSelectedFile(file);

      const updateFormData = {
        ...formData,
      };
      const updateFormDataElement = {
        ...updateFormData[event.target.name],
      };

      updateFormDataElement.valid = checkValidity(
        updateFormDataElement.value,
        updateFormData[event.target.name].validation,
        event.target,
      );

      updateFormDataElement.touched = true;
      updateFormData[event.target.name] = updateFormDataElement;

      let formIsValid = true;
      for (let key in updateFormData) {
        formIsValid = updateFormData[key].valid && formIsValid;
      }

      setControl(true);
      setFormIsValid(formIsValid);
      setFormData(updateFormData);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== 'application/pdf') {
          setImgPreviewUrl(reader.result);
        } else {
          //setImgPreviewUrl(reader.result);
          //immagine di placeholder
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl(null);
    }
  };

  const deleteFileHandler = () => {
    if (resetForm) {
      resetFormHandler();
      setFormIsValid(false);
    }
    setImgPreviewUrl(null);
  };

  useEffect(() => {
    setTimeout(() => {
      customAxios
        .get('utility/stores-full')
        .then(res => {
          if (res.status === 200) {
            //console.log("RES ", res);
            setStores(res.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }, 1000);

    getDistricts().then(data => {
      setDistricts(data);
    });
  }, []);
  useEffect(() => {
    getCountries().then(data => {
      setCountries(data);
    });
  }, []);

  useEffect(() => {
    if (cities.length === 0) {
      setZipcode('');
    }
  }, [cities]);

  useEffect(() => {
    if (startDate.date !== null) {
      for (var i = 0; i < datepickername.length; i++) {
        if (startDate.name === datepickername[i]) {
          const updateFormData = {
            ...formData,
          };
          const updateFormDataElement = {
            ...updateFormData[`${datepickername[i]}`],
          };
          if (startDate) {
            updateFormDataElement.value = startDate.date;

            updateFormDataElement.valuetosend = moment(startDate.date).format('YYYY-MM-DD');
          }

          updateFormDataElement.valid = checkValidity(
            updateFormDataElement.value,
            updateFormDataElement.validation,
            {},
          );
          updateFormDataElement.touched = true;
          updateFormData[`${datepickername[i]}`] = updateFormDataElement;

          let formIsValid = true;

          for (let key in updateFormData) {
            formIsValid = updateFormData[key].valid && formIsValid;
          }

          setControl(true);
          setFormIsValid(formIsValid);
          setFormData(updateFormData);
        }
      }
    }
  }, [startDate]);
  useEffect(() => {}, [locals]);
  const onSetFormValid = event => {
    const updateFormData = {
      ...formData,
    };
    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target,
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    const validForm = [];
    for (let key in updateFormData) {
      validForm.push(updateFormData[key].valid ? 'valid' : 'invalid');
      formIsValid = updateFormData[key].valid;
    }
    if (validForm.includes('invalid')) {
      formIsValid = false;
    }
    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };

  return {
    inputChangedHandler,
    formSubmitHandler,
    formData,
    firstSubmit,
    showError,
    problemOrderSelected,
    startDate: startDate.date,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    formIsValid,
    resetFormHandler,
    districts,
    cities,
    zipcode,
    countries,
    onSetFormValid,
    stores,
    setLocals,
    locals,
  };
};

export default useForm;
