import * as actionTypes from './actionTypes';

import { showInitialLoader, hideInitialLoader } from './ui';
import { checkAuth, checkAuthState, oauthSendToken } from './auth';

import customaxios from '../../config/axios-refresh-token';

import { sendLogError } from './error';

import { getProtocol } from '../../utils/utils';

const protocol = getProtocol();

const appInitStart = () => {
  return {
    type: actionTypes.APP_INIT_START,
  };
};

const appInitSuccess = config => {
  return {
    type: actionTypes.APP_INIT_SUCCESS,
    config,
  };
};

const appInitError = error => {
  return {
    type: actionTypes.APP_INIT_ERROR,
    error,
  };
};

export const appInit = () => {
  let endpoint = 'app/start';
  return dispatch => {
    dispatch(appInitStart());
    // let t = setTimeout(() => {
    dispatch(appInitStart()); // questa dovrebbe stare fuori dal settimeout
    dispatch(showInitialLoader());
    //  }, 0);
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(response => {
          const { data } = response;
          dispatch(appInitSuccess(data));
          dispatch(hideInitialLoader());
          //clearInterval(t);
          resolve();
        })
        .catch(error => {
          dispatch(appInitError(error.response.status));
          //clearInterval(t);
          reject(error);
          if (error.response.status >= 500) {
            dispatch(sendLogError(endpoint, error));
          }
        });
    });
  };
};

const oauthInfoStart = () => {
  return {
    type: actionTypes.OAUTH_INFO_START,
  };
};

const oauthInfoSuccess = info => {
  return {
    type: actionTypes.OAUTH_INFO_SUCCESS,
    info,
  };
};

const oauthInfoError = error => {
  return {
    type: actionTypes.OAUTH_INFO_ERROR,
    error,
  };
};

export const oauthInfo = () => {
  let endpoint = `app/oauth/info/?url=${protocol}`;
  return dispatch => {
    dispatch(oauthInfoStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(response => {
          if (response.status === 200) {
            dispatch(oauthInfoSuccess(response.data));
            resolve();
          }
        })
        .catch(error => {
          dispatch(oauthInfoError(error.response.status));
          reject(error);
          if (error.response.status >= 500) {
            dispatch(sendLogError(endpoint, error));
          }
        });
    });
  };
};

const goToPanelStart = () => {
  return {
    type: actionTypes.GO_TO_PANEL_START,
  };
};

const goToPanelSuccess = () => {
  return {
    type: actionTypes.GO_TO_PANEL_SUCCESS,
  };
};

const goToPanelError = error => {
  return {
    type: actionTypes.GO_TO_PANEL_ERROR,
    error,
  };
};

export const goToPanel = alias => {
  let endpoint = `panel/auth/init/${alias}`;
  return dispatch => {
    dispatch(goToPanelStart());
    customaxios
      .get(endpoint)
      .then(response => {
        if (response.status === 200) {
          dispatch(goToPanelSuccess());
          window.location.href = `http://localhost:3001/${response.data.appcode}`;
        }
      })
      .catch(error => {
        dispatch(goToPanelError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const appLoadingStart = () => {
  return {
    type: actionTypes.APP_LOADING_START,
  };
};

const appLoadingSuccess = () => {
  return {
    type: actionTypes.APP_LOADING_SUCCESS,
  };
};

export const appLoading = () => {
  return dispatch => {
    dispatch(appLoadingStart());
    return Promise.all([
      dispatch(appInit),
      dispatch(oauthInfo),
      dispatch(checkAuthState),
      dispatch(checkAuth),
      dispatch(oauthSendToken),
    ])
      .then(response => {
        dispatch(appLoadingSuccess());
      })
      .catch(error => {
        console.log(error);
      });
  };
};
