import React, { Suspense } from 'react';
import { Loader } from '../../components/Ui';
import SessionExpired from '../../components/SessionExpired';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useSelector } from 'react-redux';
import bannerHome from '../../assets/images/bannerHome.png';
import imgMobile from '../../assets/images/homeMobile.png';
import ContentSlide from '../../components/ContentSlide';
import { respondTo } from '../../theme/mixin';
const AuthContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
  a {
    text-transform: uppercase;
  }
`;
const ContainerGlobal = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff4e0;
`;
const AuthLayout = props => {
  const lazy = props.lazy;
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    showModalLogout,
    setShowModalLogout,
  } = props;

  let Comp = null;

  if (lazy) {
    Comp = (
      <Suspense fallback={<Loader show={true} />}>
        <C {...props} />
      </Suspense>
    );
  } else {
    Comp = <C {...props} />;
  }
  const footercontent = useSelector(state => state.cms.footer);
  return (
    <ContainerGlobal>
      <AuthContainer>
        <Header
          isLogged={isLogged}
          logo={logo}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          logout={logout}
          bootApp={bootApp}
          langs={langs}
          oauths={oauths}
          isAdult={true}
          ownLogin={ownLogin}
          oauthInfo={oauthInfo}
          userInfo={userInfo}
          oauth_profile_url={oauth_profile_url}
          showModalLogout={showModalLogout}
          setShowModalLogout={setShowModalLogout}
        />
        <main>
          <ContentSlide img={bannerHome} imgMobile={imgMobile} />

          {Comp}
        </main>

        <SessionExpired isExpired={isExpired} />
        <Footer footercontent={footercontent} />
      </AuthContainer>
    </ContainerGlobal>
  );
};

const mapStateToProps = state => {
  return {
    logo: state.app.config.logo,
    logoinverted: state.app.config.logo_inverted,
    isExpired: state.error.sessionExpired,
  };
};

export default connect(mapStateToProps)(AuthLayout);
